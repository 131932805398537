import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Translate } from "../../i18n/translate";
import { CustomerService } from "../../services";
import CustomerCard from "../../sections/CustomerCard";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import OrderCard from "../../sections/OrderCard";

function Customers() {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState({});
  const [orders, setOrders] = useState([]);

  const navigations = {
    title: "CUSTOMERS",
    items: [
      { name: "CUSTOMERS", link: "/customers" },
      { name: `${customer?.firstName} ${customer?.lastName}` },
    ],
  };

  useEffect(() => {
    fecthRecords();
  }, []);

  const fecthRecords = async () => {
    try {
      const result = await CustomerService.get(customerId);
      setCustomer(result.data);

      const orders = await CustomerService.getOrders(customerId);
      setOrders(orders.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function makeSearch(searchText) {
    // Implement the search functionality here
  }

  const navigate = useNavigate();

  return (
    <>
      <NavBreadcrumbs title="CUSTOMERS" navs={navigations} />
      <CustomerCard customer={customer} />
      <Typography
        sx={{
          mt: 5,
          mb: 3,
        }}
        variant="h4"
      >
        {Translate.translate("ORDERS")}
      </Typography>
      <Divider />
      <Grid sx={{ mt: 1 }} container spacing={3}>
        {orders.map((order, index) => (
          <OrderCard key={index} order={order} />
        ))}
      </Grid>
    </>
  );
}

export default Customers;
