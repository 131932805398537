import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { StockService } from "../../services/stock.services";
import { NumericFormat } from "react-number-format";
import { confirmAlert } from "react-confirm-alert";
import { Translate } from "../../i18n/translate";
import QRCode from "react-qr-code";
import PremiumFeatureRequiredView from "../../helpers/premiumFeature";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import TransactionTable from "../../sections/TransactionTable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // This only needs to be imported once in your app

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ViewStock = () => {
  const { stockId } = useParams();
  const navigate = useNavigate();
  const [stock, setStock] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [quantity, setQuantity] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  //cahrts
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  const navigations = {
    title: "STOCKS",
    items: [{ name: "STOCKS", link: "/stocks" }, { name: stock?.stockName }],
    navItems: [
      { id: "update", title: "UPDATE", image: "update.png" },
      { id: "delete", title: "DELETE", image: "trash.png" },
    ],
  };

  useEffect(() => {
    getStock();
    getQuantity();
    getTransactions();
    getStockQuantitiesByWeekday();
  }, []);

  const getStock = async () => {
    try {
      const result = await StockService.getOne(stockId);
      setStock(result.data);
    } catch (error) {}
  };

  const getStockQuantitiesByWeekday = async () => {
    try {
      const result = await StockService.getStockQuantitiesByWeekday(stockId);
      let days = result.data;
      let labels = [];
      let chartData = [];
      days.forEach((day) => {
        const date = moment(day.date, "DD.MM.YYYY").toDate();

        const formattedDate = date.toLocaleDateString(
          localStorage.getItem("locale") || "en",
          { weekday: "short" }
        );

        labels.push(formattedDate);
        chartData.push(day.quantity);
      });

      setLabels(labels);
      setChartData(chartData);
    } catch (error) {}
  };

  const getQuantity = async () => {
    try {
      const result = await StockService.getQuantity(stockId);
      setQuantity(result.data);
    } catch (error) {}
  };

  const getTransactions = async (page = 0) => {
    if (localStorage.getItem("isPremiumSubscriber") !== "true") {
      return;
    }
    try {
      const result = await StockService.getTransaction(stockId, page, 10);
      setTransactions(transactions.concat(result.data));
    } catch (error) {}
  };

  const totalQuantity = () => {
    return quantity.reduce((total, q) => total + q.total, 0);
  };

  const deleteConfirm = () => {
    confirmAlert({
      title: "Uyarı",
      message: "Bu Stok ve tüm stok işlemleri silinecektir. Onaylıyor musunuz?",
      buttons: [
        {
          label: "Evet",
          onClick: deleteStock,
        },
        {
          label: "Vazgeç",
        },
      ],
    });
  };

  const deleteStock = async () => {
    try {
      await StockService.deleteStock(stockId);
      navigate(`/stocks`);
    } catch (error) {}
  };

  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `${stock?.stockName}_${stock?.barcode}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  function navBarAction(id) {
    if (id === "update") {
      navigate("/updateStock/" + stock._id);
    }

    if (id === "delete") {
      deleteConfirm();
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: `${Translate.translate("STOCK_QUANTITIES_FOR_LAST_7_DAYS")}`,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Stok Miktari",
        data: chartData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.4,
      },
    ],
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxIndex(0);
  };

  function loadmore() {
    const nextPage = page + 1;
    setPage(nextPage);
    getTransactions(nextPage);
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography
            variant="h4"
            sx={{ fontStyle: "bold", mt: 2 }}
            gutterBottom
          >
            {stock?.stockName}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    {stock?.images?.length > 0 ? (
                      <img
                        width="100%"
                        src={`${process.env.REACT_APP_API_URL}/images/${stock?.images[0].image}`}
                        alt=""
                        onClick={() => openLightbox(0)}
                      />
                    ) : (
                      <img
                        width="100%"
                        src={`${process.env.REACT_APP_API_URL}/images/noimg.jpg`}
                        alt=""
                      />
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <QRCode
                      id="QRCode"
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                        cursor: "copy",
                      }}
                      value={stock?.barcode || "no"}
                      viewBox={`0 0 256 256`}
                      onClick={onImageCownload.bind(this)}
                    />
                    <center>{stock?.barcode}</center>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <TableContainer component={Paper}>
                <Typography
                  component="h4"
                  variant=""
                  color="primary"
                  sx={{ ml: 2, mt: 2, mb: 1 }}
                >
                  {Translate.translate("WAREHOUSE_STOCK_QUANTITY")}
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{Translate.translate("WAREHOUSE")}</TableCell>

                      <TableCell align="right">
                        {Translate.translate("QUANTITY")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quantity.map((q, i) => (
                      <TableRow key={i}>
                        <TableCell>{q.warehouseName}</TableCell>
                        <TableCell align="right"> {q.total}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        <b>{Translate.translate("GRAND_TOTAL")}</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>{totalQuantity()}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper sx={{ p: 2, display: "flex" }}>
                <Line options={options} data={data} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="stock tabs"
          >
            <Tab label={Translate.translate("RECENT_TRANSACTIONS")} />
            <Tab label={Translate.translate("STOCK_DETAILS")} />
            <Tab label={Translate.translate("STOCK_IMAGES")} />
          </Tabs>
          {activeTab === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <TransactionTable transactions={transactions} />
                  {localStorage.getItem("isPremiumSubscriber") === "true" && (
                    <Button
                      size="small"
                      onClick={() => loadmore()}
                      sx={{ mt: 2 }}
                    >
                      {Translate.translate("MORE")}
                    </Button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Grid item xs={12} lg={12} sx={{ mb: 1.5 }}>
                    <Grid item xs={12} lg={12} sx={{ mb: 1.5 }}>
                      {stock?.images?.length > 0 &&
                        stock?.images.map((item, index) => (
                          <img
                            key={index}
                            width={150}
                            alt=""
                            className="lightBoxImage"
                            src={`${process.env.REACT_APP_API_URL}/images/${stock?.images[index].image}`}
                            onClick={() => openLightbox(index)}
                          />
                        ))}
                    </Grid>

                    {lightboxOpen && (
                      <Lightbox
                        mainSrc={`${process.env.REACT_APP_API_URL}/images/${stock?.images[lightboxIndex].image}`}
                        nextSrc={`${process.env.REACT_APP_API_URL}/images/${
                          stock?.images[
                            (lightboxIndex + 1) % stock.images.length
                          ].image
                        }`}
                        prevSrc={`${process.env.REACT_APP_API_URL}/images/${
                          stock?.images[
                            (lightboxIndex + stock.images.length - 1) %
                              stock.images.length
                          ].image
                        }`}
                        onCloseRequest={closeLightbox}
                        onMovePrevRequest={() =>
                          setLightboxIndex(
                            (lightboxIndex + stock.images.length - 1) %
                              stock.images.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setLightboxIndex(
                            (lightboxIndex + 1) % stock.images.length
                          )
                        }
                      />
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeTab === 1 && (
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TableContainer>
                    <Typography
                      component="h4"
                      variant=""
                      color="primary"
                      sx={{ ml: 2, mb: 1 }}
                    >
                      {Translate.translate("STOCK_DETAILS")}
                    </Typography>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {Translate.translate("BARCODE")}
                          </TableCell>
                          <TableCell>{stock?.barcode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {Translate.translate("STOCK_CODE")}
                          </TableCell>
                          <TableCell>{stock?.stockCode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {Translate.translate("NOTIFICATION_LIMIT")}
                          </TableCell>
                          <TableCell>{stock?.notificationLimit}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            {Translate.translate("PURCHASE_PRICE")}
                          </TableCell>
                          <TableCell>
                            💰 {stock?.purchasePrice?.toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {Translate.translate("SALE_PRICE")}
                          </TableCell>
                          <TableCell>
                            💰 {stock?.salePrice?.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TableContainer>
                    <Typography
                      component="h4"
                      variant=""
                      color="primary"
                      sx={{ ml: 2, mb: 1 }}
                    >
                      {Translate.translate("ATTRIBUTES")}
                    </Typography>
                    <Table size="small">
                      <TableBody>
                        {stock?.attributes?.length > 0 ? (
                          stock?.attributes?.map((q, i) => (
                            <TableRow key={i}>
                              <TableCell>{q.a_name}</TableCell>
                              <TableCell align="right"> {q.a_value}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <TableCell>
                                {Translate.translate("ATTRIBUTES_NOT_FOUND")}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewStock;
