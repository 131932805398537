import { ServiceManager } from "./serviceManager";

export const TransactionService = {
  getAll,
  create,
};

function getAll(page, limit) {
  return ServiceManager.Get(`transactions?limit=${limit}&page=${page}`);
}
function create(payload) {
  return ServiceManager.Post(`transactions`, payload);
}
