import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Translate } from "../../i18n/translate";
import { OrderService } from "../../services";
import SearchBox from "../../sections/SearchBox";
import OrderCard from "../../sections/OrderCard";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

function Orders() {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(0);
  const navigations = { title: "ORDERS", items: [{ name: "ORDERS" }] };
  const navBarItemsConfig = [
   {id: "save", title: "SAVE", image: "save.png" },
  {id: "previewDeliveryNote", title: "PREVIEW_DELIVERY_NOTE",   image: "save.png", }
  ];

  useEffect(() => {
    loadItems();
  }, [page]);

  const loadItems = async () => {
    try {
      const result = await OrderService.getAll(page, 20);
      setRecords((prevStocks) => [...prevStocks, ...result.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const makeSearch = (searchText) => {};

  const onCancel = () => {
    setPage(0);
    setRecords([]);
    loadItems();
  };

  return (
    <>
      <NavBreadcrumbs title="ORDERS" navs={navigations} navBarItems={navBarItemsConfig}/>
      <SearchBox callback={makeSearch} onCancel={onCancel} />
      <Grid sx={{ mt: 3 }} container spacing={3}>
        {records.map((record, index) => (
          <OrderCard key={index} order={record} />
        ))}
      </Grid>
    </>
  );
}

export default Orders;
