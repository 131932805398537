import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import { UserService, AdminService, ReportService } from "../../services";
import DeviceCard from "../../sections/DeviceCard";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { tr } from "date-fns/locale";
import moment from "moment";
import PremiumComponent from "../../helpers/premiumFeature";

export default function Warehouses() {
  const [users, setUsers] = useState([]);
  const [setAccount] = useState();
  const [numofdevices] = useState(localStorage.getItem("numofdevices") ?? 0);
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigations = {
    title: "INVENTORY_REPORT",
    items: [
      { name: "REPORTS", link: "/reports" },
      { name: "INVENTORY_REPORT" },
    ],
    navItems: [
      { id: "createUser", title: "SELECT_DATE_RANGE", image: "calendar.png" },
    ],
  };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    saveUser();
  };

  const handleInputChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const saveUser = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(false);
    closeModal();

    setUsers([]);

    let range = {
      startDate: moment(selectionRange.startDate).format("DD.MM.YYYY"),
      endDate: moment(selectionRange.endDate).format("DD.MM.YYYY"),
    };

    const usersResponse = await ReportService.getStockInventoryReport(range);

    setUsers(usersResponse.data);

    console.log(range);
  };

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 630,
    bgcolor: "background.paper",
    border: "0px solid #000",
    p: 4,
  };

  const [open, setOpen] = useState(false);
  function navBarAction(id) {
    if (id === "createUser") {
      openModal(true);
    }
  }

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    console.log(ranges);

    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DateRangePicker
            maxDate={new Date()}
            locale={tr}
            ranges={[selectionRange]}
            onChange={handleSelect}
          />
          <br></br>

         

          {localStorage.getItem("isPremiumSubscriber") === "true" ? (
            <Button
              sx={{ mt: 2 }}
              disabled={isLoading}
              variant="contained"
              onClick={async () => {
                setIsLoading(true);
                await saveUser();
              }}
            >
              {Translate.translate("SUBMIT")}
            </Button>
          ):( <PremiumComponent />)}
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">{Translate.translate("INVENTORY_REPORT")}</Typography>
            <Typography>
              {moment(selectionRange.startDate).format("DD.MM.YYYY")} -{" "}
              {moment(selectionRange.endDate).format("DD.MM.YYYY")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{Translate.translate("BARCODE")}</TableCell>
                  <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                  <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                  <TableCell align="right"> {Translate.translate("IMPORTED")}</TableCell>
                  <TableCell align="right"> {Translate.translate("EXPORTED")}</TableCell>
                  <TableCell align="right"> {Translate.translate("SUM")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((post, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{post.stockInfo.barcode}</TableCell>
                    <TableCell>{post.stockInfo.stockCode}</TableCell>
                    <TableCell>{post.stockInfo.stockName}</TableCell>
                    <TableCell align="right">{post.imported}</TableCell>
                    <TableCell align="right">{post.exported}</TableCell>
                    <TableCell align="right">
                      {post.imported + post.exported}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
