import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Grid,
  Link,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { UserService } from "../../services/user.services";
import { AlertDialog } from "../../helpers/helper.alertDialog";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { StockService } from "../../services/stock.services";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import { Translate } from "../../i18n/translate";

export default function Warehouses() {
  const navigate = useNavigate();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const [excelData, setExcelData] = useState({ upload: false });
  const fileInput = createRef();
  const navigations = {
    title: "BULK_STOCK_IMPORT",
    items: [{ name: "STOCKS", link: "/stocks" }, { name: "BULK_STOCK_IMPORT" }],
    navItems: [
      { id: "upload", title: "UPLOAD_TEMPLATE", image: "upload.png" },
      { id: "download", title: "DOWNLOAD_TEMPLATE", image: "excel.png" },
    ],
  };
  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async (page) => {
    UserService.getAll()
      .then((result) => {
        console.log(result.data);
      })
      .catch((error) => {});
  };

  function openFileBrowser() {
    fileInput.current.click();
  }

  const fileHandler = (event) => {
    try {
      if (event.target.files.length) {
        let fileObj = event.target.files[0];
        let fileName = fileObj.name;

        //check for file extension and pass only if it is .xlsx and display error message otherwise
        if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
          setUploadedFileName(fileName);
          setIsFormInvalid(false);

          renderFile(fileObj);
        } else {
          setIsFormInvalid("Lütfen belirtilen şablonu kullanınız.");
          setUploadedFileName("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderFile = (fileObj) => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (resp.rows.length > 1000) {
        setIsFormInvalid("Tek seferde 1000 stoktan fazla yükleme yapılamaz");
        setUploadedFileName("");
      } else {
        if (err) {
          // console.log(err);
        } else {
          if (
            resp.rows[0][0] === "stok-adi" &&
            resp.rows[0][1] === "stok-kodu" &&
            resp.rows[0][2] === "barkod" &&
            resp.rows[0][3] === "alis-fiyati" &&
            resp.rows[0][4] === "satis-fiyati" &&
            resp.rows[0][5] === "uyari-limiti"
          ) {
            let t = resp.rows.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t[2] === value[2])
            );
            setExcelData({
              dataLoaded: true,
              upload: true,
              cols: resp.cols,
              rows: t,
            });
          } else {
            setIsFormInvalid("Şablonda yer almayan sütün bulunuyor");
            setUploadedFileName("");
          }
        }
      }
    });
  };

  const upload = () => {
    try {
      console.log("upload");
      let obj = excelData.rows;
      console.log(">>>>>>obj", obj);

      let newObj = obj.map((e) => {
        let i = {
          stockName: e[0],
          stockCode: e[1],
          barcode: e[2],
          purchasePrice: e[3],
          salePrice: e[4],
          notificationLimit: e[5],
        };
        return i;
      });
      // console.log(">>>>>>newObj", newObj.slice(1));
      StockService.multisave(newObj.slice(1))
        .then((result) => {
          // console.log("CREATED_RECORD__:", result);
          navigate("/stocks");
        })
        .catch((error) => {
          console.log("error message", error.response.data.message);
          AlertDialog.showAlert(
            "Mükerrer barkod kaydı olmamasına dikkat ediniz"
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  function navBarAction(id) {
    if (id === "upload") {
      openFileBrowser();
    }

    if (id === "download") {
      window.open(process.env.PUBLIC_URL + "/sablon.xlsx");
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />

      <input
        type="file"
        hidden
        onChange={fileHandler.bind(this)}
        ref={fileInput}
        onClick={(event) => {
          event.target.value = null;
        }}
      />

      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Paper sx={{ p: 2 }}>
            <Alert severity="info">
              {Translate.translate("BULK_UPDATE_INFO")}
            </Alert>

            {isFormInvalid && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {isFormInvalid}
              </Alert>
            )}

            {excelData.upload && (
              <Button
                variant="contained"
                onClick={upload.bind(this)}
                sx={{ m: 2 }}
              >
                {Translate.translate("UPLOAD")}
              </Button>
            )}

            {excelData.dataLoaded && (
              <OutTable
                data={excelData.rows}
                columns={excelData.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
