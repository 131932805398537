import { ServiceManager } from "./serviceManager";

export const CustomerService = {
  getAll,
  get,
  getOrders
};

function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`customers?limit=${limit}&page=${page}`);
}
function get(idCustomer) {
  return ServiceManager.Get(`customers/${idCustomer}`);
}
function getOrders(idCustomer) {
  return ServiceManager.Get(`customers/${idCustomer}/orders`);
}
