/* prettier-ignore */

const green = "#00D8A5"
const blue = "#5A9DE3"
const orange = "#FD7F20"
const red = "#FF572D"


const OrderStatus = {
    orderInPreparation: {
      code: 0,
      color: blue,
      name: "orderInPreparation",
      actionBarItems: ["updateOrder", "scan", "selectCustomer", "selectOrderDate","selectWarehouse", "deleteOrder"],
    },
    orderPlaced: {
      code: 100,
      color: blue,
      name: "orderPlaced",
      actionBarItems: ["updateOrder"],
    },
    orderProcessing: {
      code: 200,
      color: blue,
      name: "orderProcessing",
      actionBarItems: ["updateOrder"],
    },
    orderConfirmed: {
      code: 300,
      color: blue,
      name: "orderConfirmed",
      actionBarItems: ["updateOrder", ],
    },
    paymentProcessing: {
      code: 400,
      color: blue,
      name: "paymentProcessing",
      actionBarItems: ["updateOrder"],
    },
    orderFulfillment: {
      code: 500,
      color: orange,
      name: "orderFulfillment",
      actionBarItems: ["updateOrder"],
    },    
    orderItemsExportedFromWarehouse: {
      code: 600,
      color: orange,
      name: "orderItemsExportedFromWarehouse",
      actionBarItems: ["updateOrder"],
    },
    orderShipped: {
      code: 700,
      color: orange,
      name: "orderShipped",
      actionBarItems: ["updateOrder"],
    },
    inTransit: {
      code: 800,
      color: orange,
      name: "inTransit",
      actionBarItems: ["updateOrder"],
    },
    outForDelivery: {
      code: 900,
      color: orange,
      name: "outForDelivery",
      actionBarItems: ["updateOrder"],
    },
    orderDelivered: {
      code: 1000,
      color: green,
      name: "orderDelivered",
      actionBarItems: ["updateOrder"],
    },
    orderCompleted: {
      code: 10000,
      color: green,
      name: "orderCompleted",
      actionBarItems: ["updateOrder", ],
    },
    orderCanceled: {
      code: -100,
      color: red,
      name: "orderCanceled",
      actionBarItems: ["updateOrder", "deleteOrder"],
    },
    orderReturned: {
      code: -200,
      color: red,
      name: "orderReturned",
      actionBarItems: ["updateOrder"],
    },
    orderRefunded: {
      code: -300,
      color: red,
      name: "orderRefunded",
      actionBarItems: ["updateOrder"],
    },
    orderArchiving: {
      code: -400,
      color: red,
      name: "orderArchiving",
      actionBarItems: ["updateOrder", "deleteOrder"],
    },
/* end prettier-ignore */

  getStatusFromValue: function (value) {
    for (const statusKey in OrderStatus) {
      if (OrderStatus.hasOwnProperty(statusKey)) {
        if (OrderStatus[statusKey].code === value) {
          return statusKey;
        }
      }
    }
    return null; // Return null if the status is not found
  },

  getCurrentAction: function (orderStatus) {
    const status = this.getStatusFromValue(orderStatus);
    return OrderStatus[status]  
  },
  getActionBarItems: function (orderStatus) {
    const status = this.getStatusFromValue(orderStatus);

    const allowedNextStatuses = {
      orderInPreparation: [this.orderPlaced, this.orderCanceled      ],

      orderPlaced:        [this.orderProcessing,this.orderConfirmed,this.paymentProcessing, this.orderFulfillment,this.orderItemsExportedFromWarehouse,this.orderCanceled],
      orderProcessing:    [this.orderProcessing,this.orderConfirmed,this.paymentProcessing, this.orderFulfillment,this.orderItemsExportedFromWarehouse,this.orderCanceled],
      orderConfirmed:     [this.orderProcessing,this.orderConfirmed,this.paymentProcessing, this.orderFulfillment,this.orderItemsExportedFromWarehouse,this.orderCanceled],
      paymentProcessing:  [this.orderProcessing,this.orderConfirmed,this.paymentProcessing, this.orderFulfillment,this.orderItemsExportedFromWarehouse,this.orderCanceled],
      orderFulfillment:   [this.orderProcessing,this.orderConfirmed,this.paymentProcessing, this.orderFulfillment,this.orderItemsExportedFromWarehouse,this.orderCanceled],
     
      orderItemsExportedFromWarehouse: [this.orderShipped, this.inTransit,this.outForDelivery,this.orderDelivered, this.orderCanceled],

      orderShipped:       [this.orderShipped, this.inTransit,this.outForDelivery,this.orderDelivered, this.orderCanceled],
      inTransit:          [this.orderShipped, this.inTransit,this.outForDelivery,this.orderDelivered, this.orderCanceled],
      outForDelivery:     [this.orderShipped, this.inTransit,this.outForDelivery,this.orderDelivered, this.orderCanceled],

      orderDelivered:     [this.orderCompleted, this.orderReturned, this.orderCanceled],

      orderCompleted:     [this.orderCompleted],
      orderCanceled:      [this.orderInPreparation,this.orderArchiving],
      orderReturned:      [this.orderInPreparation,this.orderRefunded, this.orderArchiving],
      orderRefunded:      [this.orderInPreparation,this.orderArchiving],
      orderArchiving:     [this.orderArchiving]
    };
    
  
    let list = allowedNextStatuses[status] || [this.orderInPreparation]

    return list
  },
  
};

module.exports = OrderStatus;

// // Assuming the orderStatus.js file is in the same directory
// const OrderStatus = require('./orderStatus');
// orderInPreparation: 0
// orderPlaced: 100
// orderProcessing: 200
// orderConfirmed: 300
// paymentProcessing: 400
// orderFulfillment: 500
// orderItemsExportedFromWarehouse: 600
// orderShipped: 700
// inTransit: 800
// outForDelivery: 900
// orderDelivered: 1000
// orderCompleted: 10000
// orderCanceled: -100
// orderReturned: -200
// orderRefunded: -300
// orderArchiving: -400
// // Example usage:
// console.log(OrderStatus.orderPlaced); // Output: 100
// console.log(OrderStatus.allCases());  // Output: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, -100, -200, -300, -400]

// Example usage:
// const numericValue = 700;
// const status = OrderStatus.getStatusFromValue(numericValue);
// console.log(status); // Output: "inTransit"
