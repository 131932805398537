import { ServiceManager } from "./serviceManager";

export const OrderService = {
  getAll,
  get,update
};

function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`orders?limit=${limit}&page=${page}`);
}
function get(idOrder) {
  return ServiceManager.Get(`orders/${idOrder}`);
}
function update(id, payload) {
  return ServiceManager.Put(`orders/${id}`, payload);
}