import { useEffect, useState } from "react"; 
import { 
  Grid, 
  Paper, 
  Box,
  Tab,
} from "@mui/material"; 
import GeneralTab from "./tab_general";
import { AlertDialog } from "../../helpers/helper.alertDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AdminService } from "../../services";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

export default function EtModule() { 
  const [accountInfo, setAccountInfo] = useState({});
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async (page) => {
    try {
      const result = await AdminService.getAccountInfo();
      console.log(result.data);
      setAccountInfo(result.data);
    } catch (error) {
      let errorMessage = error.message; 
      console.error(errorMessage);
    }
  };

  async function updateAccountInfo(accountInfo) {
    if (!accountInfo.accountName || !accountInfo.lang) {
      return;
    }

    try {
      const result = await AdminService.updateAccountInfo({
        accountName: accountInfo.accountName,
        lang: accountInfo.lang,
      });

      AlertDialog.showSuccess(Translate.translate("OPERATION_IS_SUCCESSFUL"));
      await loadItems();
    } catch (error) {
      console.error(error);
    }
  }

  const navigations = {
    title: "SETTINGS",
    items: [{ name: "SETTINGS" }],
  };
  function takeAction(action) {
    if (action === "updateOrder") {
    }
    if (action === "selectCustomer") {
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={takeAction} />

      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Paper sx={{ p: 2 }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label=""
                >
                  <Tab label={Translate.translate("ACCOUNT_NAME")} value="1" />
                  {/* <Tab label="Bilgilendirmeler" value="2" />  */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <GeneralTab
                  account={accountInfo}
                  callback={(values) => {
                    console.log("fromCallback", values);
                    updateAccountInfo(values);
                  }}
                />
              </TabPanel>
              {/* <TabPanel value="2"><NotificationTab/></TabPanel>  */}
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
