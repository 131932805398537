const Translations = {
  INVENTORY_REPORT: {
    en: "Inventory Transaction Report",
    tr: "Tarih Aralıklı Stok Haraket Raporu",
  },
  INVENTORY_REPORT_DESCRIPTION: {
    en: "This report will show you the imported and exported stock values according to any date range you select. ",
    tr: "Bu rapor, seçtiğiniz herhangi bir tarih aralığına göre içe ve dışa aktarılan stok miktarlarını gösterir. ",
  },
  SELECT_DATE_RANGE: {
    en: "Select Date Range",
    tr: "Tarih Aralığı Seç",
  },
  REPORT_NAME: {
    en: "Report Name",
    tr: "Rapor Adı",
  },
  REPORT_DESCRIPTION: {
    en: "Report Description",
    tr: "Rapor Açıkalaması",
  },
  IMPORTED: {
    en: "Imported",
    tr: "Giren",
  },
  EXPORTED: {
    en: "Exported",
    tr: "Çıkan",
  },
  SUM: {
    en: "Sum",
    tr: "Kalan",
  },
  INVENTORY_QUANTITY_STATUS_REPORT: {
    en: "Inventory Quantity Status Report",
    tr: "Envanter Durum Raporu",
  },
  INVENTORY_QUANTITY_STATUS_REPORT_DESCRIPTION: {
    en: "This report shows current inventory and its quantities in all the warehouses. It doesnt contains the stock that has no import or export transactions.",
    tr: "Bu rapor, tüm depolardaki mevcut envanteri ve miktarlarını gösterir. Giriş veya Çıkış işlemi olmayan stokları içermez.",
  },
  
};

export default Translations;
