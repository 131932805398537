import { ServiceManager } from "./serviceManager";

export const StockService = {
  getAll,
  getOne,
  getTransaction,
  getQuantity,
  save,
  search,
  update,
  deleteImage,
  multisave,
  deleteStock,
  getStockQuantitiesByWeekday
};

function getStockQuantitiesByWeekday(id) {
  return ServiceManager.Get(
    `stocks/${id}/getStockQuantitiesByWeekday`
  );
}
function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`stocks?limit=${limit}&page=${page}`);
}

function save(payload) {
  return ServiceManager.Post(`stocks`, payload);
}

// --------------------------------------
function getOne(id) {
  return ServiceManager.Get(`stocks/${id}`);
}

function getTransaction(id, page = 0, limit = 10) {
  return ServiceManager.Get(
    `stocks/${id}/transactions?limit=${limit}&page=${page}`
  );
}
function getQuantity(id) {
  return ServiceManager.Get(`stocks/${id}/quantity`);
}

function search(payload) {
  return ServiceManager.Post(`stocks/search`, payload);
}

function update(id, payload) {
  return ServiceManager.Put(`stocks/${id}`, payload);
}

function deleteImage(id, idImage) {
  return ServiceManager.Delete(`stocks/${id}/images/${idImage}`);
}

function multisave(payload) {
  return ServiceManager.Post(`stocks/multi`, payload);
}

function deleteStock(id) {
  return ServiceManager.Delete(`stocks/${id}`);
}