import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";

const TOKEN_KEY = "token";

const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp >= Date.now() / 1000;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

const RequireAuth = () => {
  const location = useLocation();
  const token = localStorage.getItem(TOKEN_KEY);

  if (!isTokenValid(token)) {
    // Token is invalid or expired, handle this case securely
    localStorage.removeItem(TOKEN_KEY);
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    // Token is valid, execute code based on environment
    if (process.env.NODE_ENV === "development") {
      console.log("This code is running in the development environment.");
    } else {
      // Set premium subscriber and number of devices in localStorage
      try {
        const decodedToken = jwtDecode(token);
        localStorage.setItem("isPremiumSubscriber", decodedToken.isPremiumSubscriber);
        localStorage.setItem("numofdevices", decodedToken.numofdevices);
      } catch (error) {
        console.error("Error decoding token:", error);
        // Handle the error or fallback behavior here if needed
      }
    }

    return <Outlet />;
  }
};

export default RequireAuth;
