import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Translate } from "../i18n/translate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  maxHeight:"500px",
  overflowY:"scroll",
  p: 1,
};

export default function SelectView(props) {
  const { open, handleClose } = props;
  const handleItemSelect = (id) =>
    props.callback({
      id: props.selectViewItems.id,
      callbackId: id,
    });
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <nav aria-label="secondary mailbox folders">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {props.items?.title}
            </Typography>
            <List>
            {props.selectViewItems?.items.map((item, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton onClick={() => handleItemSelect(item.id)}>
                    <ListItemText
                      style={{ color: item.color || "#000" }}
                      primary={item.title}
                    />
                  </ListItemButton>
                </ListItem>
              ))}   
              <ListItem disablePadding>
                <ListItemButton
                  
                  onClick={() => props.callback({ id: "close", callbackId: 0 })}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 20,
                      fontWeight: "medium", 
                      color:"#121212"
                    }}
                    style={{ fontWeight: "800" }}
                    primary={Translate.translate("CLOSE")}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Modal>
    </div>
  );
}
