import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import { UserService, AdminService, ReportService } from "../../services";
import DeviceCard from "../../sections/DeviceCard";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { tr } from "date-fns/locale";
import moment from "moment";
import PremiumComponent from "../../helpers/premiumFeature";

export default function Warehouses() {
  const [users, setUsers] = useState([]);
  const [setAccount] = useState();
  const [numofdevices] = useState(localStorage.getItem("numofdevices") ?? 0);
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigations = {
    title: "INVENTORY_QUANTITY_STATUS_REPORT",
    items: [
      { name: "REPORTS", link: "/reports" },
      { name: "INVENTORY_QUANTITY_STATUS_REPORT" },
    ],
    navItems: [
      // { id: "createUser", title: "PRINT", image: "print.png" },
    ],
  };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    saveUser();
  };

  const handleInputChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const saveUser = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(false);
  
    if ( localStorage.getItem("isPremiumSubscriber") !== "true" ){
       return
     }

    setUsers([]);

    let range = {
      startDate: moment(selectionRange.startDate).format("DD.MM.YYYY"),
      endDate: moment(selectionRange.endDate).format("DD.MM.YYYY"),
    };

    const usersResponse = await ReportService.inventoryQuantityStatusReport();

    setUsers(usersResponse.data);

    console.log(range);
  };

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 630,
    bgcolor: "background.paper",
    border: "0px solid #000",
    p: 4,
  };

  const [open, setOpen] = useState(false);
  function navBarAction(id) {
    if (id === "createUser") {
      openModal(true);
    }
  }

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    console.log(ranges);

    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">{Translate.translate("INVENTORY_QUANTITY_STATUS_REPORT")}</Typography>
            
            {localStorage.getItem("isPremiumSubscriber") == "true" ? (
            <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{Translate.translate("BARCODE")}</TableCell>
                <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                <TableCell align="right"> {Translate.translate("SUM")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((post, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{post.stock?.barcode}</TableCell>
                  <TableCell>{post.stock?.stockCode}</TableCell>
                  <TableCell>{post.stock?.stockName}</TableCell>
                  <TableCell align="right">
                    {post.sum}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          ):( <PremiumComponent />)}

            
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
