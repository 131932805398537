const StocksLang = {
  CREATE_STOCK: {
    en: "Create Stock",
    tr: "Stok Oluştur",
  },
  UPDATE_STOCK: {
    en: "Update Stock",
    tr: "Stok Güncelle",
  },
  
  DUPLICATE_BARCODE_ERROR: {
    en: "Check the barcode/QR you enter, make sure it is not already registered",
    tr: "Giriş yaptığınız barkod/QR kontrol edin, zaten kayıtlı olmadığına emin olunuz"
  },
  STOCK_DETAILS: {
    en: "Stock Details",
    tr: "Stok Detayları"
  },
  STOCK_QUANTITIES_FOR_LAST_7_DAYS: {
    en: "Stock quantities for last 7 days",
    tr: "Son 7 günlük stok miktarları",
  },
  BULK_STOCK_IMPORT: {
    en: "Bulk Stock Importy",
    tr: "Toplu Stok Yükleme",
  },
  UPLOAD_TEMPLATE: {
    en: "Upload Template",
    tr: "Şablonu Yükle",
  },
  DOWNLOAD_TEMPLATE: {
    en: "Download Template",
    tr: "Şablonu İndir",
  },
  BULK_UPDATE_INFO: {
    en: "You can upload your stocks in bulk using the template file on the page.",
    tr: "Sayfada bulunan şablon dosyasını kullanarak stoklarınızı topluca yükleyebilirsiniz.",
  },
  
  
};

const CombinedStocks = { ...StocksLang };

export default CombinedStocks;