import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import { UserService, AdminService } from "../../services";
import DeviceCard from "../../sections/DeviceCard";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
export default function Warehouses() {
  const navigate = useNavigate();
  const navigations = {
    title: "REPORTS",
    items: [{ name: "REPORTS" }],
    navItems: [],
  };

  function navBarAction(id) {
    if (id === "createUser") {
    }
  }
  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell>{Translate.translate("REPORT_NAME")}</TableCell>
                  <TableCell>
                    {Translate.translate("REPORT_DESCRIPTION")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Button
                      onClick={() => {
                        navigate("/reports/inventoryReport", { replace: true });
                      }}
                      size="small"
                      variant="outlined"
                    >
                      {Translate.translate("VIEW_RECORD")}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {Translate.translate("INVENTORY_REPORT")}
                  </TableCell>
                  <TableCell>
                    {Translate.translate("INVENTORY_REPORT_DESCRIPTION")}
                  </TableCell>
                </TableRow>{" "}
                <TableRow>
                  <TableCell align="left">
                    <Button
                      onClick={() => {
                        navigate("/reports/inventoryQuantityStatusReport", {
                          replace: true,
                        });
                      }}
                      size="small"
                      variant="outlined"
                    >
                      {Translate.translate("VIEW_RECORD")}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {Translate.translate("INVENTORY_QUANTITY_STATUS_REPORT")}
                  </TableCell>
                  <TableCell>
                    {Translate.translate(
                      "INVENTORY_QUANTITY_STATUS_REPORT_DESCRIPTION"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
