const TranslationGeneral = {
    MY_REGISTER: {
      en: "Register",
      tr: "Kayıt Ol",
    },
    MY_LOGIN: {
      en: "Login",
      tr: "Giriş",
    },
    WELCOME_SCREEN_1: {
      en: "Easy and Efficient Inventory Management",
      tr: "Envanterinizi Kolay ve Verimli Yönetin",
    },
    WELCOME_SCREEN_2: {
      en: "Define Your Items",
      tr: "Ürünlerinizi Sistemde Tanımlayın",
    },
    WELCOME_SCREEN_3: {
      en: "Scan In and Out Your Items Using Your Barcode Scanner",
      tr: "Barkod Okuyucunuzu Kullanarak Stok Giriş Çıkış İşlemi Yapın",
    },
    WELCOME_SCREEN_4: {
      en: "Track the Latest Status of Your Warehouse with Reports",
      tr: "Raporlar ile Deponuzun Son Durumunu Takip Edin",
    },
    WELCOME_SCREEN_IMAGE_1: {
      en: "en_1",
      tr: "tr_1",
    },
    WELCOME_SCREEN_IMAGE_2: {
      en: "en_2",
      tr: "tr_1",
    },
    WELCOME_SCREEN_IMAGE_3: {
      en: "en_3",
      tr: "tr_1",
    },
    WELCOME_SCREEN_IMAGE_4: {
      en: "en_4",
      tr: "tr_1",
    },
    REGISTER: {
      en: "Register",
      tr: "Kayıt Ol",
    },
    NEXT: {
      en: "Next",
      tr: "Sonraki",
    },
    ALREADY_HAVE_AN_ACCOUNT: {
      en: "Already have an account?",
      tr: "Zaten Hesabınız var mı?",
    },
    LOGIN: {
      en: "Login",
      tr: "Giriş",
    },
    APP_NAME: {
      en: "El Terminali",
      tr: "El Terminali",
    },
    BACK: {
      en: "Back",
      tr: "Geri",
    },
    PASSWORD_RESET: {
      en: "Password Reset",
      tr: "Şifre Yenileme",
    },
    DEVICE_LOGIN: {
      en: "Device Login",
      tr: "Cihaz Girişi",
    },
    EMAIL: {
      en: "Email",
      tr: "E-Posta",
    },
    PASSWORD: {
      en: "Password (min 6, max 10)",
      tr: "Şifre (min 6, maks 10)",
    },
    NOT_A_VALID_EMAIL: {
      en: "Please enter a valid email",
      tr: "Lütfen uygun e-posta adresini girin",
    },
    DEMO_ACCOUNT: {
      en: "demo_en@eltrm.com",
      tr: "demo_tr@eltrm.com",
    },
    DEMO_ACCOUNT_BUTTON: {
      en: "Demo Account",
      tr: "Demo Hesap",
    },
    SAVE: {
      en: "Save",
      tr: "Kaydet",
    },
    REGISTER_PAGE_INFO: {
      en: "Please make sure that you enter your e-mail address correctly, otherwise you will not be able to change your password and receive information e-mails. If you have any problems, you can send an e-mail to info@eltrm.com.",
      tr: "Lütfen email adresinizi doğru girdiğinizden emin olunuz aksi durumda şifrenizi değiştiremez ve bilgilendirme emaillerini alamazsınız. Problem yaşamanız durumunda info@eltrm.com adresine email atabilirsiniz.",
    },
    FIRST_NAME: {
      en: "First Name",
      tr: "İsim",
    },
    LAST_NAME: {
      en: "Last Name",
      tr: "Soy isim",
    },
    SET_NEW_PASSWORD: {
      en: "Set new Password",
      tr: "Yeni Şifre Belirle",
    },
    SET_NEW_PASSWORD_INFO: {
      en: "Please renew your password by entering the code sent to your e-mail address.",
      tr: "Lütfen email adresinize gönderilmiş olan kodu girerek şifrenizi yenileyiniz.",
    },
    ACTIVATION_CODE: {
      en: "Activation Code",
      tr: "Aktivasyon Kodu",
    },
    PLEASE_ENTER_VALID_EMAIL: {
      en: "Please enter a valid password.",
      tr: "Lütfen geçerli bir şifre giriniz.",
    },
    THIS_FIELD_IS_MANDATORY: {
      en: "This field is mandatory",
      tr: "Bu alan zorunludur",
    },
    OPERATION_IS_SUCCESSFUL: {
      en: "The process was completed with success",
      tr: "İşlem baraşı ile tamamlandı",
    },
    SUCCESSFUL: {
      en: "Success",
      tr: "Başarılı",
    },
    OK: {
      en: "OK",
      tr: "Tamam",
    },
    DASHBOARD: {
      en: "Dashboard",
      tr: "Dashboard",
    },
    STOCKS: {
      en: "Items",
      tr: "Stoklar",
    },
    WAREHOUSE: {
      en: "Warehouse",
      tr: "Depo",
    },
    WAREHOUSES: {
      en: "Warehouses",
      tr: "Depolar",
    },
    SETTINGS: {
      en: "Settings",
      tr: "Ayarlar",
    },
    REFRESH_PAGE: {
      en: "Refresh Page",
      tr: "Sayfayı Yenile",
    },
    NUMBER_OF_SAVED_STOCKS: {
      en: "Number of Items",
      tr: "Kayıtlı Stok",
    },
    NUMBER_OF_STOCKS_IN_INVENTORY: {
      en: "Total Item Quantity",
      tr: "Toplam Stok Miktarı",
    },
    NUMBER_OF_SAVED_WAREHOUSES: {
      en: "Number of Saved Warehouses",
      tr: "Kayıtlı Depo Adeti",
    },
    TOTAL_FINANCIAL_VALUE_OF_STOCKS_IN_INVENTORY: {
      en: "Total Financial Value of Items in Inventory",
      tr: "Stokta Bulunan Ürünlerin Toplam Mali Değeri",
    },
    ACCOUNT_SUMMARY: {
      en: "ACCOUNT SUMMARY",
      tr: "HESAP ÖZETİ",
    },
    REPORTS: {
      en: "Reports",
      tr: "Raporlar",
    },
    RECENT_TRANSACTIONS: {
      en: "Recent Transactions",
      tr: "Stok Hareketleri",
    },
    PRODUCTS_WITH_DECREASING_STOCK_QUANTITY: {
      en: "Items with Decreasing Quantity",
      tr: "Miktarı Azalan Stoklar",
    },
    IMPORT_STOCK_SHORT: {
      en: "In",
      tr: "Giriş",
    },
    EXPORT_STOCK_SHORT: {
      en: "Out",
      tr: "Çıkış",
    },
    FIND_STOCK_SHORT: {
      en: "Find",
      tr: "Stk. Bul",
    },
    STOCK_COUNTING_SHORT: {
      en: "Count",
      tr: "Stk. Sayım",
    },
    IMPORT_STOCK: {
      en: "Import Items",
      tr: "Stok Giriş",
    },
    EXPORT_STOCK: {
      en: "Export Items",
      tr: "Stok Çıkış",
    },
    FIND_STOCK: {
      en: "Find Items",
      tr: "Stok Bul",
    },
    STOCK_COUNTING: {
      en: "Item Counting",
      tr: "Stok Sayım",
    },
  
    WEB_LOGIN: {
      en: "Web Login",
      tr: "Web Girişi",
    },
    SELECT: {
      en: "Select",
      tr: "Seç",
    },
    CLOSE: {
      en: "Close",
      tr: "Kapat",
    },
    ATLEAST_ONE_WAREHOUSE_REQIRED: {
      en: "You need to create at least one warehouse!",
      tr: "En az bir tane depo oluşturmalısınız!",
    },
    WEB_LOGIN_BUTTON_INFO: {
      en: "To manage your items and warehouses from your computer with El Terminali, please visit www.eltrm.com.",
      tr: "El Termimali ile stoklarınızı ve depolarınızı bilgisayarınızdan yönetebilmek için lütfen www.eltrm.com adresini ziyaret ediniz.",
    },
    SCANNED_STOCK_SEND_CONFIRMATION: {
      en: "Scanned items have been sent to your email address.",
      tr: "Taranmış stoklar email adresinize gönderildi.",
    },
    SEARCH: {
      en: "Search",
      tr: "Ara",
    },
    NEW: {
      en: "New",
      tr: "Yeni",
    },
    STOCK_DELETION: {
      en: "Item Deletion",
      tr: "Stok Silme",
    },
    STOCK_DELETE_CONFIRMATION_TITLE: {
      en: "Type YES for Confirmation",
      tr: "Onay için EVET yazınız",
    },
    STOCK_DELETE_CONFIRMATION_TEXT: {
      en: "This process will delete the item and all related stock movements.",
      tr: "Bu işlem stok ve stok ile ilgili tüm stok haraketlerini silecektir.",
    },
    DELETE: {
      en: "Delete",
      tr: "Sil",
    },
    YES_CONFIRMATION: {
      en: "YES",
      tr: "EVET",
    },
    STOCK_DELETION_FAILED: {
      en: "Operation was not successful!",
      tr: "İşlem gerçekleşmedi!",
    },
    NO_STOCK_TITLE: {
      en: "No Items Found",
      tr: "Stok Bulunamadı",
    },
    NO_STOCK_TEXT: {
      en: "You can create a new item right away by clicking the 'New' button.",
      tr: "Yeni tuşu ile hemen bir stok oluşturabilirsiniz.",
    },
    STOCK_NAME: {
      en: "Item Name",
      tr: "Stok Adı",
    },
    STOCK_CODE: {
      en: "Item Code",
      tr: "Stok Kodu",
    },
    BARCODE: {
      en: "Barcode",
      tr: "Barkod",
    },
    PURCHASE_PRICE: {
      en: "Purchase Price",
      tr: "Alış Fiyatı",
    },
    SALE_PRICE: {
      en: "Sale Price",
      tr: "Satış Fiyatı",
    },
    NOTIFICATION_LIMIT: {
      en: "Stock quantity notification limit",
      tr: "Stok miktar bildirim limiti",
    },
    UPDATE: {
      en: "Update",
      tr: "Güncelle",
    },
    STOCK_PICTURES: {
      en: "Item Pictures",
      tr: "Stok Resimleri",
    },
    STOCK_INFO: {
      en: "Item Information",
      tr: "Stok Bilgileri",
    },
    WAREHOUSE_STOCK_QUANTITY: {
      en: "Stock Quantities by Warehouse",
      tr: "Depolara Göre Stok Miktarları",
    },
    NO_STOCK_IMAGE_FOUND_TITLE: {
      en: "No Stock Image Found",
      tr: "Stok Resmi bulunamadı",
    },
    NO_STOCK_IMAGE_FOUND_INFO: {
      en: "You can add an image right away by clicking the '+' button in the top right corner.",
      tr: "Sağ üstteki + tuşu ile hemen bir resim ekleyebilirsiniz.",
    },
    NO_WAREHOUSE_FOUND_TITLE: {
      en: "No Warehouse Found",
      tr: "Depo bulunamadı",
    },
    NO_WAREHOUSE_FOUND_INFO: {
      en: "You can create a new warehouse right away by clicking the 'New' button.",
      tr: "Yeni tuşu ile hemen bir depo oluşturabilirsiniz.",
    },
    WAREHOUSE_TRANSFER: {
      en: "Transfer",
      tr: "Transfer",
    },
    WAREHOUSE_DELETION: {
      en: "Warehouse Deletion",
      tr: "Depo Silme",
    },
    WAREHOUSE_DELETE_CONFIRMATION_TITLE: {
      en: "Type YES for Confirmation",
      tr: "Onay için EVET yazınız",
    },
    WAREHOUSE_DELETE_CONFIRMATION_TEXT: {
      en: "This process will delete the warehouse and all related stock movements.",
      tr: "Bu işlem stok ve stok ile ilgili tüm stok haraketlerini silecektir.",
    },
    WAREHOUSE_DELETION_FAILED: {
      en: "Operation was not successful!",
      tr: "İşlem gerçekleşmedi!",
    },
    INVENTORY: {
      en: "Inventory",
      tr: "Envanter",
    },
    LAST_100_TRANSACTIONS: {
      en: "Last 100 Warehouse Stock Movements",
      tr: "Son 100 Depo Stok Haraketi",
    },
    DOWNLOAD: {
      en: "Download",
      tr: "İndir",
    },
    INVENTORY_LIST_DOWNLOAD_ALERT_TEXT: {
      en: "To download the inventory list to your computer, please visit www.eltrm.com. You can log in by scanning the QR code on the site.",
      tr: "Envanter listesini bilgisayarınıza indirmek için lütfen www.eltrm.com adresini ziyaret ediniz. Sitedeki QR kodu okutarak giriş yapabilirsiniz.",
    },
    INVENTORY_LIST: {
      en: "Warehouse Inventory List",
      tr: "Depo Envanter Listesi",
    },
    EXPORT_WAREHOUSE: {
      en: "Warehouse to Export From",
      tr: "Çıkış Yapılacak Depo",
    },
    IMPORT_WAREHOUSE: {
      en: "Warehouse to Import Into",
      tr: "Giriş Yapılacak Depo",
    },
    SCAN_STOCKS: {
      en: "Scan Items",
      tr: "Stk. Tara",
    },
    SELECT_EXPORT_WAREHOUSE_ALERT_TEXT: {
      en: "You must select a warehouse to export from!",
      tr: "Çıkış Yapılacak Deposunu Seçmelisiniz!",
    },
    SELECT_IMPORT_WAREHOUSE_ALERT_TEXT: {
      en: "You must select a warehouse to import into!",
      tr: "Giriş Yapılacak Deposunu Seçmelisiniz!",
    },
    YOU_CAN_NOT_TRANSFER_SAME_WAREHOUSE_ALERT_TEXT: {
      en: "You cannot transfer to the same warehouse!",
      tr: "Aynı depoya transfer yapamazsınız!",
    },
    NO_STOCK_FOUND_TO_TRANSFER: {
      en: "No items found to transfer!",
      tr: "Transfer yapılacak stok bulunamadı!",
    },
    WAREHOUSE_STOCK_TRANSFER: {
      en: "Warehouse Stock Transfer",
      tr: "Depo Stok Transfer",
    },
    WAREHOUSE_STOCK_TRANSFER_APPROVAL_ALER_TEXT: {
      en: "Are you sure you want to approve the warehouse stock transfer?",
      tr: "Depo Stok Transfer işlemini onaylıyor musunuz?",
    },
    STOCKS_FOR_TRANSFER: {
      en: "Items for Transfer",
      tr: "Transfer Yapılacak Stoklar",
    },
    TRANSFER_BETWEEN_WAREHOUSES: {
      en: "Transfer Between Warehouses",
      tr: "Depolar Arası Transfer",
    },
    SCAN: {
      en: "Scan",
      tr: "Okut",
    },
    STOCK_NOT_FOUND: {
      en: "No items found!",
      tr: "Stok bulunamadı!",
    },
    MANUAL: {
      en: "Manual",
      tr: "Manuel",
    },
    FLASH: {
      en: "Flash",
      tr: "Flaş",
    },
    MULTIPLY: {
      en: "Multiply",
      tr: "Çarpan",
    },
    BIP: {
      en: "Bip",
      tr: "Bip",
    },
    BIP_OFF: {
      en: "Bip Off",
      tr: "Bip Yok",
    },
    STOCKS_ARE_NOT_SAVED: {
      en: "Items are not saved!",
      tr: "Stoklar Kaydedilmedi!",
    },
    WARNING: {
      en: "Warning",
      tr: "Uyarı",
    },
    APPROVAL_TO_CONTINUE_ALET_TEXT: {
      en: "Are you sure you want to continue?",
      tr: "İşleme devam edilecek onaylıyor musunuz?",
    },
    NOTE: {
      en: "Note",
      tr: "Not",
    },
    MANUAL_BARCODE_ENTRY: {
      en: "Manual Barcode Entry",
      tr: "El ile barkod girişi",
    },
    QUANTITY: {
      en: "Quantity",
      tr: "Miktar",
    },
    TOTAL_QUANTITY: {
      en: "Total Quantity",
      tr: "Toplam Birim",
    },
    TOTAL_VALUE: {
      en: "Total Value",
      tr: "Toplam Tutar",
    },
    MY_PROFILE: {
      en: "My Profile",
      tr: "Profilim",
    },
    SUBSCRIPTION: {
      en: "Subscription",
      tr: "Üyelik",
    },
    CUSTOMER_SUPPORT: {
      en: "Customer Support",
      tr: "Müşteri Destek",
    },
    SUBSCRIPTIONS: {
      en: "Subscriptions",
      tr: "Üyelikler",
    },
    LOG_OUT: {
      en: "Log Out",
      tr: "Çıkış",
    },
    LOG_OUT_APPROVAL_ALERT_TEXT: {
      en: "Are you sure you want to log out?",
      tr: "Uygulamadan çıkış yapılacak onaylıyor musunuz?",
    },
    CUSTOMER_SUPPORT_URL: {
      en: "https://elterminali.freshdesk.com/",
      tr: "https://elterminali.freshdesk.com/",
    },
    PREMIUM_SUBSCRIPTION: {
      en: "Premium Subscription",
      tr: "Premium Üyelik",
    },
    PREMIUM_SUBSCRIPTION_INFO: {
      en: "Ad-free usage and personalized one-on-one support",
      tr: "Reklamsız kullanım ve size özel bire bir destek",
    },
    RECOVER_PURCHASES_SHORT: {
      en: "Recover Purchases",
      tr: "Üylk geri yükle",
    },
    HAVE_ALREADY_PREMIUM_SUBSCRIPTION: {
      en: "You already have a Premium subscription!",
      tr: "Zaten Premium Üyesiniz!",
    },
    SUBSCRIPTION_TYPE: {
      en: "Subscription Type",
      tr: "Üyelik Türü",
    },
    EXPLANATION: {
      en: "Explanation",
      tr: "Açıklama",
    },
    SUBSCRIPTION_DURATION: {
      en: "Subscription Duration",
      tr: "Üyelik Süresi",
    },
    SUBSCRIPTION_PRICE: {
      en: "Subscription Price",
      tr: "Üyelik Bedeli",
    },
    SUBSCRIBE: {
      en: "Subscribe",
      tr: "Üye Ol",
    },
    RECOVER_PURCHASES: {
      en: "Recover Purchases",
      tr: "Satınalmaları geri yükle",
    },
    TERMS_AND_CONDITIONS: {
      en: "Terms and Conditions",
      tr: "Hüküm ve koşullar",
    },
    PRIVACY: {
      en: "Privacy",
      tr: "Gizlilik",
    },
    EXPLANATIONS_1: {
      en: "Upon confirmation of the purchase process, the iTunes Account holder will be charged.",
      tr: "Satın alma işleminin onaylanması üzerine iTunes Hesabı sahibi ücretlendirilecektir",
    },
    EXPLANATIONS_2: {
      en: "The subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period.",
      tr: "Abonelik, geçerli dönemin bitiminden en az 24 saat önce otomatik yenileme kapalı olmadığı sürece otomatik olarak yenilenir",
    },
    EXPLANATIONS_3: {
      en: "The account will be charged for renewal within 24 hours prior to the end of the current period, and identify the cost of the renewal.",
      tr: "Hesap, cari döneme ait bitimden 24 saat önce yenileme için ücretlendirilecek ve yenileme bedelini belirleyecektir",
    },
    EXPLANATIONS_4: {
      en: "Subscriptions can be managed by the user, and auto-renewal may be turned off by going to the user's Account Settings after purchase.",
      tr: "Abonelikler kullanıcı tarafından yönetilebilir ve kullanıcının satın alındıktan sonra Hesap Ayarlarına giderek otomatik yenileme kapatılabilir",
    },
    SUBSCRIPTION_DETAILS: {
      en: "Subscription Details",
      tr: "Üyelik Detayları",
    },
    ACTIONS: {
      en: "Actions",
      tr: "Eylemler",
    },
    CONDITIONS: {
      en: "Conditions",
      tr: "Koşullar",
    },
    TERMS_AND_CONDITIONS_URL: {
      en: "https://eltrm.com/terms",
      tr: "https://eltrm.com/terms",
    },
    PRIVACY_URL: {
      en: "https://eltrm.com/privacy",
      tr: "https://eltrm.com/privacy",
    },
    YES: {
      en: "Yes",
      tr: "Evet",
    },
    CANCEL: {
      en: "Cancel",
      tr: "Vazgeç",
    },
    DELETE_ACCOUNT_SHORT: {
      en: "Close Account",
      tr: "Hesabı Kapa",
    },
    DELETE_ACCOUNT: {
      en: "Close Account",
      tr: "Hesabı Kapat",
    },
    DELETE_ACCOUNT_ALERT_TEXT: {
      en: "Your account and all data will be deleted. Are you sure?",
      tr: "Hesabınız ve tüm verileriniz silinecektir. Onaylıyor musunuz?",
    },
    INFO: {
      en: "Info",
      tr: "Bilgi",
    },
    WAREHOUSE_NAME: {
      en: "Warehouse Name",
      tr: "Depo Adı",
    },
    CREATE_WAREHOUSE: {
      en: "Create Warehouse",
      tr: "Depo Oluştur",
    },
    UPDATE_WAREHOUSE: {
      en: "Update Warehouse",
      tr: "Depo Güncelleme",
    },
    WAREHOUSE_NAME_IS_MANDATORY: {
      en: "Warehouse Name is mandatory",
      tr: "Depo Adı boş bırakılamaz",
    },
    PREMIMUM_SUBSCRIPTION: {
      en: "Premium Subscription",
      tr: "Premium Üyelik",
    },
    NSCameraUsageDescription: {
      en: "You must allow camera access for the application to read barcodes and QR codes.",
      tr: "Uygulamanın barkod ve QR kod okuyabilmesi için kamera erişimine izin vermelisiniz.",
    },
    CAMERA: {
      en: "Camera",
      tr: "Kamera",
    },
    MORE: {
      en: "More",
      tr: "Daha Fazla",
    },
    STOCK_OUT: {
      en: "Stock Out",
      tr: "Stok Çıkış",
    },
    STOCK_IN: {
      en: "Stock In",
      tr: "Stok Giriş",
    },
    TRANSFER_OUT: {
      en: "Warehouse Transfer Out",
      tr: "Depo Transfer Çıkış",
    },
    TRANSFER_IN: {
      en: "Warehouse Transfer In",
      tr: "Depo Transfer Giriş",
    },
    USERS: {
      en: "Users",
      tr: "Kullanıcılar",
    },
    INTEGRATION: {
      en: "Integration",
      tr: "Entegrasyon",
    },
    INTEGRATION_INFO: {
      en: "The integration process is developed specifically for each company. Therefore, the integration method and the integrated data may vary for each company. For integration requests, please contact info@eltrm.com.",
      tr: "Entegrasyon işlemi her firmaya özel olarak geliştirilmektedir. Dolayısı ile her firmanın entegrasyon yöntemi ve entegre ettiği veri farklıdır. Bu yüzden entegrasyon talepleriniz için lütfen info@eltrm.com ile ietişime geçiniz.",
    },
    GRAND_TOTAL: {
      en: "Grand Total",
      tr: "Genel Toplam",
    },
    ACCOUNT_NAME: {
      en: "Account Name",
      tr: "Hesap Adı",
    },
    IMPORT_FROM_EXCEL: {
      en: "Import from Excel",
      tr: "Excel'den Yükle",
    },
    ACTION: {
      en: "Action",
      tr: "Eylem",
    },
    USER: {
      en: "User",
      tr: "Kullanıcı",
    },
    DATE: {
      en: "Date",
      tr: "Tarih",
    },
    ATTRIBUTES: {
      en: "Attributes",
      tr: "Özellikler",
    },
    ATTRIBUTES_NOT_FOUND: {
      en: "No additional attribute information found.",
      tr: "Kayıtlı ek özellik bilgisi bulunmuyor",
    },
    STOCK_IMAGES: {
      en: "Stock Images",
      tr: "Stok Resimleri",
    },
    PREMIUM_FEATURE: {
      en: "Premium Feature",
      tr: "Premium Özellik",
    },
    PREMIUM_CONTENT_TITLE: {
      en: "Premium subscription is required to view the content.",
      tr: "İçeriği görüntülemek için Premium abonelik gereklidir",
    },
    PREMIUM_CONTENT_EXPLANATION: {
      en: "To become a member, please visit the 'Settings > Subscription' page on your mobile phone.",
      tr: "Üye olmak için lütfen mobil telefonunuzdan 'ayarlar > üyelik' sayfasını ziyaret edin.",
    },
    DEVICE: {
      en: "Device",
      tr: "Cihaz",
    },
    APP_VERSION: {
      en: "App Version",
      tr: "Uygulama Versiyonu",
    },
    DEVICE_ID: {
      en: "Device ID",
      tr: "Cihaz No",
    },
    SIGNIN_AGAIN: {
      en: "Sign In Again",
      tr: "Tekrar Giriş Yap",
    },
    ACTIVATE: {
      en: "Activate",
      tr: "Etkinleştir",
    },
    DEACTIVATE: {
      en: "Deactivate",
      tr: "Engelle",
    },
    MAIN_ACCOUNT: {
      en: "Main Account",
      tr: "Ana Hesap",
    },
    QR_LOGIN_INFO: {
      en: "Scan the QR code above with the device you want to use and refresh the page.",
      tr: "Yukarıdaki QR kodu kullanmak istediğiniz cihazınız ile okutun ve sayfayı yenileyiniz.",
    },
    CREATE_USER: {
      en: "Create User",
      tr: "Kullanıcı Oluştur",
    },
    CREATE_USER_INFO_TEXT: {
      en: "Number of devices you can use with your current subscription:",
      tr: "Geçerli üyeliğiniz ile kullanabileceğiniz cihaz sayısı:",
    },
    PREMIUM_SEARCH_ADVANTAGE: {
      en: "Press Enter to search or click on the magnifying glass icon. Note: Premium members can also search by typing.",
      tr: "Arama yapmak için enter tuşuna basın veya büyüteç simgesine tıklayın. Not: Premium üyeler sadece yazarak da arama yapabilirler.",
    },
    ERR_EMAIL_EXIST: {
      en: "This email is already registered. Please try changing your password.",
      tr: "Bu email kayıtlı, lütfen şifrenizi değiştirmeyi deneyiniz.",
    },
    SET_NEW_PASSWORD_TITLE: {
      en: "Please set your new password using the code sent to your email address.",
      tr: "Lütfen e-posta adresinize gönderilen kod ile yeni şifrenizi belirleyiniz.",
    },
    RESET_PASSWORD: {
      en: "Reset Password",
      tr: "Şifre Belirleme",
    },
    PASSWORD_RESET_TEXT: {
      en: "Please enter your email address and relevant instructions will be sent to your email address.",
      tr: "Lütfen e-posta adresinizi girin; ilgili talimatlar e-posta adresinize gönderilecektir.",
    },
    ERR_USER_NOT_REGISTERED: {
      en: "E-mail address not found",
      tr: "E-posta adresi bulunamadı",
    },
    MISSING_REQUIRED_FIELDS: {
      en: "Please fill in the required fields.",
      tr: "Lütfen gerekli alanları doldurunuz.",
    },
    CUSTOMERS: {
      en: "Customers",
      tr: "Müşteriler",
    },

    VIEW_RECORD:{
      en: "View",
      tr: "İncele",
    },
    NOTIFICATIONS_LANGUAGE:{
      en: "Email Notifications Language",
      tr: "E-posta Bildirim Dili",
    },
    UPLOAD:{
      en: "Upload",
      tr: "Yükle",
    },
    SUBMIT: {
      en: "Submit",
      tr: "Gönder",
    },
    
  };
  
  export default TranslationGeneral;
  