import TranslationArrays from "./locales";

function translate(id, value = {}) {
  const lang = localStorage.getItem("locale") || "en";
  const text = TranslationArrays[id] && TranslationArrays[id][lang];
  return text || id;
}

export const Translate = {
  translate,
};
