import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Translate } from "../i18n/translate";
import { NumericFormat } from "react-number-format";
import OrderStatus from "../helpers/OrderStatus";

export default function OrderCard(props) {
  const navigate = useNavigate();
  const handleclick = () => navigate(`/orders/${props.order._id}`);

  const { order } = props;
  const { orderDate, customer, orderNo, grandTotal, warehouse } = order;

  const orderStatus = OrderStatus.getStatusFromValue(order.orderStatus);
  const orderStatusColor = OrderStatus[orderStatus].color;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={handleclick}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14, color: orderStatusColor }}
              gutterBottom
            >
              {Translate.translate(orderStatus)}
            </Typography>
            <Typography variant="h5" component="div">
              {orderNo}
            </Typography>
            <Typography color="text.secondary">
              📅 {moment(orderDate).format("DD. MMMM YYYY")}
            </Typography>
            <Typography color="text.secondary">
              🥷 {customer && customer[0]?.firstName}{" "}
              {customer && customer[0]?.lastName}
            </Typography>
            {warehouse && (
              <Typography color="text.secondary">
                🚛 {warehouse[0]?.warehouseName}
              </Typography>
            )}
            <Typography variant="h6">
              💰 {grandTotal.toLocaleString()}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
