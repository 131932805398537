import { toast } from "react-toastify";

const showAlert = (text, callback) => {
  toast.error(text, {
    position: "top-center",
    autoClose: 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const showSuccess = (text,callback) => {
  toast.success(text, {
    position: "top-center",
    autoClose: 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const AlertDialog = {
  showAlert,
  showSuccess,
};
