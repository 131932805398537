import { ServiceManager } from "./serviceManager";

export const ReportService = {
  dashboard,getStockInventoryReport,inventoryQuantityStatusReport
};

function dashboard() {
  return ServiceManager.Get(`reports/dashboard`);
}

function getStockInventoryReport(payload) {
  return ServiceManager.Post(`reports/getStockInventoryReport`,payload);
}
function inventoryQuantityStatusReport() {
  return ServiceManager.Get(`reports/inventoryQuantityStatusReport`);
}


