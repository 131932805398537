import SystemGeneral from "./general";
import Orders from "./orders.lang";
import Stocks from "./stocks.lang";
import Reports from "./reports.lang";

const TranslationArrays = {
  ...SystemGeneral,
  ...Orders,
  ...Stocks,
  ...Reports

};
export default TranslationArrays;
