import React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { Translate } from "../i18n/translate";
export default function NavBarItem(props) {
  return (
     
      <Card sx={{ maxWidth: 345 ,mr:1,mt:1}}>
        <CardActionArea onClick={() => props.callback(props.id)}>
          <CardContent>
            <center>
              <img
                height="30"
                src={`${process.env.PUBLIC_URL}/icons/${props.image}`}
                alt=""
              />
            </center>
            <center>{Translate.translate(props.title)}</center>
          </CardContent>
        </CardActionArea>
      </Card>
     
  );
}
