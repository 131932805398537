import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Paper, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { StockService } from "../../services/stock.services";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import { AlertDialog } from "../../helpers/helper.alertDialog";

export default function UpdateStock() {
  const { stockId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    stockName: "",
    stockCode: "",
    barcode: "",
    purchasePrice: "",
    salePrice: "",
    notificationLimit: "",
  });

  useEffect(() => {
    if (stockId !== undefined) {
      fetchStock();
    }
  }, []);

  const navigations = {
    title: stockId === undefined ? "CREATE_STOCK" : "UPDATE_STOCK",
    items: [
      { name: "STOCKS", link: "/stocks" },
      { name: values.stockName, link: "/stocks/" + stockId },
      { name: stockId === undefined ? "CREATE_STOCK" : "UPDATE_STOCK" },
    ],
    navItems: [{ id: "save", title: "SAVE", image: "save.png" }],
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function fetchStock() {
    try {
      const result = await StockService.getOne(stockId);
      setValues(result.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message?.message ||
        "An error occurred while fetching stock data.";
      AlertDialog.showAlert(errorMessage);
    }
  }

  async function submit() {
    try {
      if (stockId === undefined) {
        const saveResult = await StockService.save(values);
        const newStockId = saveResult.data._id;
        navigate(`/stocks/${newStockId}`);
      } else {
        await StockService.update(stockId, values);
        navigate(`/stocks/${stockId}`);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while processing your request.";

      AlertDialog.showAlert(Translate.translate("ERROR"), errorMessage);
    }
  }

  function navBarAction(action) {
    const { stockName, barcode } = values;

    if (action === "save" && stockName.length > 0 && barcode.length > 0) {
      submit();
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <TextField
              error={values.stockName.length === 0}
             
              label={Translate.translate("STOCK_NAME")}
              id="outlined-start-adornment"
              fullWidth
              value={values.stockName}
              onChange={handleChange("stockName")}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              
              label={Translate.translate("STOCK_CODE")}
              id="outlined-start-adornment"
              fullWidth
              value={values.stockCode}
              onChange={handleChange("stockCode")}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              
              error={values.barcode.length === 0}
              label={Translate.translate("BARCODE")}
              fullWidth
              value={values.barcode}
              onChange={handleChange("barcode")}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              
              label={Translate.translate("PURCHASE_PRICE")}
              id="outlined-start-adornment"
              type="number"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">💰</InputAdornment>
                ),
              }}
              value={values.purchasePrice}
              onChange={handleChange("purchasePrice")}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              
              label={Translate.translate("SALE_PRICE")}
              id="outlined-start-adornment"
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">💰</InputAdornment>
                ),
              }}
              value={values.salePrice}
              onChange={handleChange("salePrice")}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="notificationLimit"
              
              label={Translate.translate("NOTIFICATION_LIMIT")}
              fullWidth
              type="number"
              value={values.notificationLimit}
              onChange={handleChange("notificationLimit")}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
