import { ServiceManager } from "../helpers/serviceManager";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
class AuthService {
  static instance = null;

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  currentUser = null;

  signIn = async (email, password) => {
    try {
      let response = await ServiceManager.Post("auth/signin", {
        email: email,
        password: password,
        source: "WEB",
      });
      this.currentUser = response.data;
      return Promise.resolve(this.currentUser);
    } catch (error) { 
      return Promise.reject(error);
    }
  };

  signUp = async (email, password) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        throw new Error("Sign up failed");
      }
      const { user } = await response.json();
      this.currentUser = user;
      return user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  resetPassword = async (email) => {
    try {
      const response = await fetch("/api/auth/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error("Reset password failed");
      }
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export default AuthService;
