import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WarningRounded } from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 140,
          }}
        >
          <WarningRounded color="secondary" sx={{ fontSize: 40 }} />
          The page you're looking for cannot be found. How about trying the
          homepage?
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Error;
