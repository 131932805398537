import { ServiceManager } from "./serviceManager";

export const AdminService = {
  getAccountInfo,
  updateAccountInfo
};

function getAccountInfo() {
  return ServiceManager.Get(`admin/accountInfo`);
}

function updateAccountInfo(payload) {
  return ServiceManager.Put(`admin/accountInfo`,payload);
}
