import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button, Grid, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { WarehouseService } from "../../services/warehouse.services";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

export default function InputAdornments() {
  const [values, setValues] = React.useState({
    warehouseName: "",
  });

  let { warehouseId } = useParams();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const navigations = {
    title: "WAREHOUSES",
    items: [
      { name: "WAREHOUSES", link: "/warehouses" },
      {
        name:
          warehouseId === undefined ? "CREATE_WAREHOUSE" : "UPDATE_WAREHOUSE",
      },
    ],
    navItems: [{ id: "save", title: "SAVE", image: "save.png" }],
  };

  useEffect(() => {
    if (warehouseId !== undefined) {
      getStock();
    }
  }, []);

  const getStock = () => {
    WarehouseService.getOne(warehouseId)
      .then((result) => setValues(result.data))
      .catch((error) => {
        let errorMessage = error.message;
      });
  };

  const submit = async () => {
    if (warehouseId === undefined) {
      WarehouseService.save(values)
        .then((result) => navigate("/warehouses/" + result.data._id))
        .catch((error) => {
          let err = error.response.data.message;
          console.log("error------", err);
          alert(
            "Giriş yaptığınız barkod/QR kontrol edin, zaten kayıtlı olmadığına emin olunuz"
          );
        });
    } else {
      //update warehouse
      let wh = await WarehouseService.update(warehouseId, values);
      navigate("/warehouses/" + warehouseId);
    }
  };

  const navigate = useNavigate();
  function navBarAction(id) {
    if (id === "save") {
      submit();
    }
  }
  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2 }}>
            <TextField
              error={values.warehouseName.length === 0}
              sx={{ mb: 3 }}
              label={Translate.translate("WAREHOUSE_NAME")}
              fullWidth
              value={values.warehouseName}
              onChange={handleChange("warehouseName")}
            />
          </Paper>
 
        </Grid>
      </Grid>
    </>
  );
}
