import { 
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea, 
  CardActions,
} from "@mui/material"; 
import { useNavigate } from "react-router-dom"; 

export default function CustomerCard(props) {
  const navigate = useNavigate();
  const handleclick = () => navigate(`/customers/${props.customer._id}`);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={handleclick}>
          <CardContent>
            <Typography variant="h5" component="div">
              {props.customer?.firstName} {props.customer?.lastName}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              ＠ {props.customer?.email} <br /> 📞 {props.customer?.phone}
            </Typography>
            <Typography variant="body2">
              {props.customer?.billing_address?.address}{" "}
              {props.customer?.billing_address?.postCode} <br />
              {props.customer?.billing_address?.city}{" "}
              {props.customer?.billing_address?.country}
            </Typography>
          </CardContent> 
        </CardActionArea>
      </Card>
    </Grid>
  );
}
