import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransactionService } from "../../services/transaction.services";
import { WarehouseService } from "../../services/warehouse.services";
import { StockService } from "../../services/stock.services";
import { AlertDialog } from "../../helpers/helper.alertDialog";
import { Translate } from "../../i18n/translate";
import SearchBox from "../../sections/SearchBox";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AddCircle, Clear } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";

const TransactionPage = () => {
  const navigate = useNavigate();

  const [stocks, setStocks] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [transactionType, setTransactionType] = useState("export");
  const [description, setDescription] = useState("");
  const [itemQuantities, setItemQuantities] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const result = await WarehouseService.getAll(100, 1);
      setWarehouses(result.data);
      setSelectedWarehouse(result.data[0]._id);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    if (searchText === "") {
      setStocks([]);
      return;
    }
    if (localStorage.getItem("isPremiumSubscriber") === "true" || e.key === "Enter") {
      executeSearch(searchText);
    }
  };

  const executeSearch = async (searchText) => {
    if (searchText === "") {
      setStocks([]);
      return;
    }
    try {
      const result = await StockService.search({ searchText });
      setStocks(result.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const addItemToTransactions = (item) => {
    const quantity = itemQuantities[item._id] > 0 ? itemQuantities[item._id] : 1;
    const itemExists = transactions.some((transaction) => transaction._id === item._id);
    if (itemExists) {
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction._id === item._id
            ? { ...transaction, quantity: Number(transaction.quantity) + Number(quantity)}
            : transaction
        )
      );
    } else {
      setTransactions((prevTransactions) => [...prevTransactions, { ...item, quantity }]);
    }
  };

  const removeItemFromTransactions = (id) => {
    setTransactions((prevTransactions) => prevTransactions.filter((item) => item._id !== id));
  };

  const handleQuantityChange = (e) => {
    setItemQuantities({ ...itemQuantities, [e.target.name]: e.target.value });
  };

  const createTransaction = async () => {
    setLoading(true);
    const finalPayload = transactions.map((transaction) => ({
      stock: transaction._id,
      warehouse: selectedWarehouse,
      quantity: transactionType === "export" ? -transaction.quantity : transaction.quantity,
      description,
    }));

    try {
      await TransactionService.create(finalPayload);
      setStocks([]);
      setTransactions([]);
      AlertDialog.showSuccess(Translate.translate("OPERATION_IS_SUCCESSFUL"));
    } catch (error) {
      console.error("Error creating transaction:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateGrandTotal = () => {
    return transactions.reduce((total, transaction) => total + transaction.salePrice * transaction.quantity, 0);
  };

  const breadcrumbs = {
    title: transactionType === "export" ? "STOCK_OUT" : "STOCK_IN",
    items: [
      { name: "WAREHOUSE" },
      { name: transactionType === "export" ? "STOCK_OUT" : "STOCK_IN" },
    ],
  };

  return (
    <>
      <NavBreadcrumbs navs={breadcrumbs} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <SearchBox
            callback={executeSearch}
            onCancel={() => setStocks([])}
          />
          <Grid item sx={{ mt: 3 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {Translate.translate("STOCKS")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                    <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                    <TableCell>{Translate.translate("BARCODE")}</TableCell>
                    <TableCell>{Translate.translate("QUANTITY")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stocks.map((stock) => (
                    <TableRow key={stock._id}>
                      <TableCell>{stock.stockName}</TableCell>
                      <TableCell>{stock.stockCode}</TableCell>
                      <TableCell>{stock.barcode}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          size="small"
                          name={stock._id}
                          value={itemQuantities[stock._id] || 1}
                          onChange={handleQuantityChange}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => addItemToTransactions(stock)} color="success">
                          <AddCircle />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, mb: 5 }}>
            <Typography component="h2" variant="h6" color="primary" sx={{ mb: 3 }}>
              {Translate.translate("ACTIONS")}
            </Typography>
            <TextField
              select
              label={Translate.translate("WAREHOUSES")}
              sx={{ mb: 3 }}
              value={selectedWarehouse}
              onChange={(e) => setSelectedWarehouse(e.target.value)}
              SelectProps={{ native: true }}
              fullWidth
            >
              {warehouses.map((warehouse) => (
                <option key={warehouse._id} value={warehouse._id}>
                  {warehouse.warehouseName}
                </option>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              label={Translate.translate("ACTIONS")}
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
              sx={{ mb: 3 }}
              SelectProps={{ native: true }}
            >
              <option value="export">{Translate.translate("STOCK_OUT")}</option>
              <option value="import">{Translate.translate("STOCK_IN")}</option>
            </TextField>
            <TextField
              sx={{ mb: 3 }}
              label={Translate.translate("EXPLANATION")}
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              disabled={transactions.length === 0 || loading}
              variant="contained"
              onClick={createTransaction}
            >
              {Translate.translate("SAVE")}
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              {Translate.translate("STOCKS")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                  <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                  <TableCell>{Translate.translate("BARCODE")}</TableCell>
                  <TableCell>{Translate.translate("SALE_PRICE")}</TableCell>
                  <TableCell>{Translate.translate("QUANTITY")}</TableCell>
                  <TableCell>{Translate.translate("TOTAL_VALUE")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction._id}>
                    <TableCell>{transaction.stockName}</TableCell>
                    <TableCell>{transaction.stockCode}</TableCell>
                    <TableCell>{transaction.barcode}</TableCell>
                    <TableCell>
                      <NumericFormat
                        value={transaction.salePrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"₺"}
                      />
                    </TableCell>
                    <TableCell>{transaction.quantity}</TableCell>
                    <TableCell>
                      <NumericFormat
                        value={transaction.salePrice * transaction.quantity}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"₺"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton size="small" onClick={() => removeItemFromTransactions(transaction._id)} color="error">
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5}>{Translate.translate("GRAND_TOTAL")}</TableCell>
                  <TableCell>
                    <NumericFormat
                      value={calculateGrandTotal()}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={"₺"}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionPage;
