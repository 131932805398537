import React from "react";
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Translate } from "../../i18n/translate";
import { useNavigate } from "react-router-dom";

const OrderItemsTable = (props) => {
  const navigate = useNavigate();
  const orderItemsGrand = props.orderItems.reduce(
    (total, item) => total + item?.salePrice * item?.quantity,
    0
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {Translate.translate("ORDERS_ITEMS")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                    <TableCell>{Translate.translate("BARCODE")}</TableCell>
                    <TableCell>{Translate.translate("QUANTITY")}</TableCell>
                    <TableCell>{Translate.translate("SALE_PRICE")}</TableCell>
                    <TableCell align="right">
                      {Translate.translate("TOTAL")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.orderItems.map((t, i) => (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{t.stockName}</TableCell>
                      <TableCell>
                        <Link
                          href="#"
                          onClick={() => navigate("/stocks/" + t._id)}
                        >
                          {t.barcode}
                        </Link>
                      </TableCell>
                      <TableCell>{t.quantity}</TableCell>
                      <TableCell>{t.salePrice}</TableCell>
                      <TableCell align="right">
                        {t.salePrice * t.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={1}>
                      {Translate.translate("GRAND_TOTAL")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      💰 {orderItemsGrand.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OrderItemsTable;
