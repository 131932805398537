//
// https://github.com/pbakondy/ios-device-list/blob/master/devices.json
// https://gist.github.com/adamawolf/3048717 - current one
//
module.exports = [
  { name: "iPhone Simulator", model: "i386" },
  { name: "iPhone Simulator", model: "x86_64" },
  { name: "iPhone Simulator", model: "arm64" },
  { name: "iPhone", model: "iPhone1,1" },
  { name: "iPhone 3G", model: "iPhone1,2" },
  { name: "iPhone 3GS", model: "iPhone2,1" },
  { name: "iPhone 4", model: "iPhone3,1" },
  { name: "iPhone 4 GSM Rev A", model: "iPhone3,2" },
  { name: "iPhone 4 CDMA", model: "iPhone3,3" },
  { name: "iPhone 4S", model: "iPhone4,1" },
  { name: "iPhone 5 (GSM)", model: "iPhone5,1" },
  { name: "iPhone 5 (GSM+CDMA)", model: "iPhone5,2" },
  { name: "iPhone 5C (GSM)", model: "iPhone5,3" },
  { name: "iPhone 5C (Global)", model: "iPhone5,4" },
  { name: "iPhone 5S (GSM)", model: "iPhone6,1" },
  { name: "iPhone 5S (Global)", model: "iPhone6,2" },
  { name: "iPhone 6 Plus", model: "iPhone7,1" },
  { name: "iPhone 6", model: "iPhone7,2" },
  { name: "iPhone 6s", model: "iPhone8,1" },
  { name: "iPhone 6s Plus", model: "iPhone8,2" },
  { name: "iPhone SE (GSM)", model: "iPhone8,4" },
  { name: "iPhone 7", model: "iPhone9,1" },
  { name: "iPhone 7 Plus", model: "iPhone9,2" },
  { name: "iPhone 7", model: "iPhone9,3" },
  { name: "iPhone 7 Plus", model: "iPhone9,4" },
  { name: "iPhone 8", model: "iPhone10,1" },
  { name: "iPhone 8 Plus", model: "iPhone10,2" },
  { name: "iPhone X Global", model: "iPhone10,3" },
  { name: "iPhone 8", model: "iPhone10,4" },
  { name: "iPhone 8 Plus", model: "iPhone10,5" },
  { name: "iPhone X GSM", model: "iPhone10,6" },
  { name: "iPhone XS", model: "iPhone11,2" },
  { name: "iPhone XS Max", model: "iPhone11,4" },
  { name: "iPhone XS Max Global", model: "iPhone11,6" },
  { name: "iPhone XR", model: "iPhone11,8" },
  { name: "iPhone 11", model: "iPhone12,1" },
  { name: "iPhone 11 Pro", model: "iPhone12,3" },
  { name: "iPhone 11 Pro Max", model: "iPhone12,5" },
  { name: "iPhone SE 2nd Gen", model: "iPhone12,8" },
  { name: "iPhone 12 Mini", model: "iPhone13,1" },
  { name: "iPhone 12", model: "iPhone13,2" },
  { name: "iPhone 12 Pro", model: "iPhone13,3" },
  { name: "iPhone 12 Pro Max", model: "iPhone13,4" },
  { name: "iPhone 13 Pro", model: "iPhone14,2" },
  { name: "iPhone 13 Pro Max", model: "iPhone14,3" },
  { name: "iPhone 13 Mini", model: "iPhone14,4" },
  { name: "iPhone 13", model: "iPhone14,5" },
  { name: "iPhone SE 3rd Gen", model: "iPhone14,6" },
  { name: "iPhone 14", model: "iPhone14,7" },
  { name: "iPhone 14 Plus", model: "iPhone14,8" },
  { name: "iPhone 14 Pro", model: "iPhone15,2" },
  { name: "iPhone 14 Pro Max", model: "iPhone15,3" },
  { name: "iPhone 15", model: "iPhone15,4" },
  { name: "iPhone 15 Plus", model: "iPhone15,5" },
  { name: "iPhone 15 Pro", model: "iPhone16,1" },
  { name: "iPhone 15 Pro Max", model: "iPhone16,2" },
  { name: "iPad", model: "iPad1,1" },
  { name: "iPad 3G", model: "iPad1,2" },
  { name: "2nd Gen iPad", model: "iPad2,1" },
  { name: "2nd Gen iPad GSM", model: "iPad2,2" },
  { name: "2nd Gen iPad CDMA", model: "iPad2,3" },
  { name: "2nd Gen iPad New Revision", model: "iPad2,4" },
  { name: "3rd Gen iPad", model: "iPad3,1" },
  { name: "3rd Gen iPad CDMA", model: "iPad3,2" },
  { name: "3rd Gen iPad GSM", model: "iPad3,3" },
  { name: "iPad mini", model: "iPad2,5" },
  { name: "iPad mini GSM+LTE", model: "iPad2,6" },
  { name: "iPad mini CDMA+LTE", model: "iPad2,7" },
  { name: "4th Gen iPad", model: "iPad3,4" },
  { name: "4th Gen iPad GSM+LTE", model: "iPad3,5" },
  { name: "4th Gen iPad CDMA+LTE", model: "iPad3,6" },
  { name: "iPad Air (WiFi)", model: "iPad4,1" },
  { name: "iPad Air (GSM+CDMA)", model: "iPad4,2" },
  { name: "1st Gen iPad Air (China)", model: "iPad4,3" },
  { name: "iPad mini Retina (WiFi)", model: "iPad4,4" },
  { name: "iPad mini Retina (GSM+CDMA)", model: "iPad4,5" },
  { name: "iPad mini Retina (China)", model: "iPad4,6" },
  { name: "iPad mini 3 (WiFi)", model: "iPad4,7" },
  { name: "iPad mini 3 (GSM+CDMA)", model: "iPad4,8" },
  { name: "iPad Mini 3 (China)", model: "iPad4,9" },
  { name: "iPad mini 4 (WiFi)", model: "iPad5,1" },
  { name: "4th Gen iPad mini (WiFi+Cellular)", model: "iPad5,2" },
  { name: "iPad Air 2 (WiFi)", model: "iPad5,3" },
  { name: "iPad Air 2 (Cellular)", model: "iPad5,4" },
  { name: "iPad Pro (9.7 inch, WiFi)", model: "iPad6,3" },
  { name: "iPad Pro (9.7 inch, WiFi+LTE)", model: "iPad6,4" },
  { name: "iPad Pro (12.9 inch, WiFi)", model: "iPad6,7" },
  { name: "iPad Pro (12.9 inch, WiFi+LTE)", model: "iPad6,8" },
  { name: "iPad (2017)", model: "iPad6,11" },
  { name: "iPad (2017)", model: "iPad6,12" },
  { name: "iPad Pro 2nd Gen (WiFi)", model: "iPad7,1" },
  { name: "iPad Pro 2nd Gen (WiFi+Cellular)", model: "iPad7,2" },
  { name: "iPad Pro 10.5-inch 2nd Gen", model: "iPad7,3" },
  { name: "iPad Pro 10.5-inch 2nd Gen", model: "iPad7,4" },
  { name: "iPad 6th Gen (WiFi)", model: "iPad7,5" },
  { name: "iPad 6th Gen (WiFi+Cellular)", model: "iPad7,6" },
  { name: "iPad 7th Gen 10.2-inch (WiFi)", model: "iPad7,11" },
  { name: "iPad 7th Gen 10.2-inch (WiFi+Cellular)", model: "iPad7,12" },
  { name: "iPad Pro 11 inch 3rd Gen (WiFi)", model: "iPad8,1" },
  { name: "iPad Pro 11 inch 3rd Gen (1TB, WiFi)", model: "iPad8,2" },
  { name: "iPad Pro 11 inch 3rd Gen (WiFi+Cellular)", model: "iPad8,3" },
  { name: "iPad Pro 11 inch 3rd Gen (1TB, WiFi+Cellular)", model: "iPad8,4" },
  { name: "iPad Pro 12.9 inch 3rd Gen (WiFi)", model: "iPad8,5" },
  { name: "iPad Pro 12.9 inch 3rd Gen (1TB, WiFi)", model: "iPad8,6" },
  { name: "iPad Pro 12.9 inch 3rd Gen (WiFi+Cellular)", model: "iPad8,7" },
  { name: "iPad Pro 12.9 inch 3rd Gen (1TB, WiFi+Cellular)", model: "iPad8,8" },
  { name: "iPad Pro 11 inch 4th Gen (WiFi)", model: "iPad8,9" },
  { name: "iPad Pro 11 inch 4th Gen (WiFi+Cellular)", model: "iPad8,10" },
  { name: "iPad Pro 12.9 inch 4th Gen (WiFi)", model: "iPad8,11" },
  { name: "iPad Pro 12.9 inch 4th Gen (WiFi+Cellular)", model: "iPad8,12" },
  { name: "iPad mini 5th Gen (WiFi)", model: "iPad11,1" },
  { name: "iPad mini 5th Gen", model: "iPad11,2" },
  { name: "iPad Air 3rd Gen (WiFi)", model: "iPad11,3" },
  { name: "iPad Air 3rd Gen", model: "iPad11,4" },
  { name: "iPad 8th Gen (WiFi)", model: "iPad11,6" },
  { name: "iPad 8th Gen (WiFi+Cellular)", model: "iPad11,7" },
  { name: "iPad 9th Gen (WiFi)", model: "iPad12,1" },
  { name: "iPad 9th Gen (WiFi+Cellular)", model: "iPad12,2" },
  { name: "iPad mini 6th Gen (WiFi)", model: "iPad14,1" },
  { name: "iPad mini 6th Gen (WiFi+Cellular)", model: "iPad14,2" },
  { name: "iPad Air 4th Gen (WiFi)", model: "iPad13,1" },
  { name: "iPad Air 4th Gen (WiFi+Cellular)", model: "iPad13,2" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad13,4" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad13,5" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad13,6" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad13,7" },
  { name: "iPad Pro 12.9 inch 5th Gen", model: "iPad13,8" },
  { name: "iPad Pro 12.9 inch 5th Gen", model: "iPad13,9" },
  { name: "iPad Pro 12.9 inch 5th Gen", model: "iPad13,10" },
  { name: "iPad Pro 12.9 inch 5th Gen", model: "iPad13,11" },
  { name: "iPad Air 5th Gen (WiFi)", model: "iPad13,16" },
  { name: "iPad Air 5th Gen (WiFi+Cellular)", model: "iPad13,17" },
  { name: "iPad 10th Gen", model: "iPad13,18" },
  { name: "iPad 10th Gen", model: "iPad13,19" },
  { name: "iPad Pro 11 inch 4th Gen", model: "iPad14,3" },
  { name: "iPad Pro 11 inch 4th Gen", model: "iPad14,4" },
  { name: "iPad Pro 12.9 inch 6th Gen", model: "iPad14,5" },
  { name: "iPad Pro 12.9 inch 6th Gen", model: "iPad14,6" },
  { name: "iPad Air 6th Gen", model: "iPad14,8" },
  { name: "iPad Air 6th Gen", model: "iPad14,9" },
  { name: "iPad Air 7th Gen", model: "iPad14,10" },
  { name: "iPad Air 7th Gen", model: "iPad14,11" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad16,3" },
  { name: "iPad Pro 11 inch 5th Gen", model: "iPad16,4" },
  { name: "iPad Pro 12.9 inch 7th Gen", model: "iPad16,5" },
  { name: "iPad Pro 12.9 inch 7th Gen", model: "iPad16,6" },
];
