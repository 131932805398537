import { ServiceManager } from "./serviceManager";

export const WarehouseService = {
  getAll,
  getOne,
  getTransaction,
  getQuantity,
  save,
  search,
  update,
  getEnvanter,
  deleteOne
};

function getAll(page, limit) {
  return ServiceManager.Get(`warehouses?limit=100&page=0`);
}

function getOne(id) {
  return ServiceManager.Get(`warehouses/${id}`);
}

function getTransaction(id, page = 0, limit = 10) {
  return ServiceManager.Get(
    `warehouses/${id}/transactions?limit=${limit}&page=${page}`
  );
}
function getQuantity(id) {
  return ServiceManager.Get(`warehouses/${id}/quantity`);
}

function getEnvanter(id) {
  return ServiceManager.Get(`warehouses/${id}/envanter`);
}

function save(payload) {
  return ServiceManager.Post(`warehouses`, payload);
}

function search(payload) {
  return ServiceManager.Post(`warehouses/search`, payload);
}

function update(id, payload) {
  return ServiceManager.Put(`warehouses/${id}`, payload);
}
function deleteOne(id) {
  return ServiceManager.Delete(`warehouses/${id}`);
}
