import React from 'react';
import { Rocket } from '@mui/icons-material';
import { Translate } from '../i18n/translate';
import { Chip } from '@mui/material';

const PremiumComponent = () => {
  return (
    <div className="container">
      <div className="centered-content">
        <Chip
          icon={<Rocket />}
          color="success"
          label={Translate.translate('PREMIUM_FEATURE')}
        />
        <p>{Translate.translate('PREMIUM_CONTENT_TITLE')}</p>
         {Translate.translate('PREMIUM_CONTENT_EXPLANATION')} 
      </div>
    </div>
  );
};

export default PremiumComponent;
