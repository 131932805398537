import { Outlet, useNavigate } from "react-router-dom";
import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton"; 
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; 
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"; 
import { Avatar, Button, ButtonGroup, Tooltip } from "@mui/material"; 
import { Translate } from "../i18n/translate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { LangContext } from "../i18n/LangContext"; 

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

const mdTheme = createTheme({
 
});

function DashboardContent() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const navigateToURL = (path) => {
    navigate(path, { replace: true });
  };

  const { setLangContext } = React.useContext(LangContext);
  function setLocale(locale) {
    console.log("event", locale);
    localStorage.setItem("locale", locale);
    setLangContext(locale);
  }
   
  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              eltrm
            </Typography>

 

            <Box>
              <Tooltip title={localStorage.getItem("fullName")}>
                <Avatar>{localStorage.getItem("nameInitials")}</Avatar>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={() => navigateToURL("/")}>
              <ListItemIcon>
                <img
                  height="30"
                  src={`${process.env.PUBLIC_URL}/dashboard.png`}
                  alt=""
                />
              </ListItemIcon>

              <ListItemText primary={Translate.translate("DASHBOARD")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/stocks")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/stocks.png`}   alt=""/>
              </ListItemIcon>
              <ListItemText primary={Translate.translate("STOCKS")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/warehouses")}>
              <ListItemIcon>
                <img
                  height="30"
                  src={`${process.env.PUBLIC_URL}/warehouse.png`}  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("WAREHOUSES")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/transactions")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/updown.png`}   alt=""/>
              </ListItemIcon>
              <ListItemText primary={Translate.translate("IMPORT_STOCK")} />
            </ListItemButton>

            <ListItemButton onClick={() => navigateToURL("/customers")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/customers.png`}  alt="" />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("CUSTOMERS")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/orders")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/orders.png`}  alt="" />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("ORDERS")} />
            </ListItemButton>

            <ListItemButton onClick={() => navigateToURL("/users")}>
              <ListItemIcon>
                <img
                  height="30"
                  src={`${process.env.PUBLIC_URL}/devices.png`}  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("USERS")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/reports")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/icons/icons8-graph-report-64.png`}  alt="" />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("REPORTS")} />
            </ListItemButton>
            <ListItemButton onClick={() => navigateToURL("/integrations")}>
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/api.png`}  alt="" />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("INTEGRATION")} />
            </ListItemButton>
          
            <ListItemButton onClick={() => navigateToURL("/settings")}>
              <ListItemIcon>
                <img
                  height="30"
                  src={`${process.env.PUBLIC_URL}/settings.png`}  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={Translate.translate("SETTINGS")} />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />

            <ListItemButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/login", { replace: true });
              }}
            >
              <ListItemIcon>
                <img height="30" src={`${process.env.PUBLIC_URL}/exit.png`}   alt=""/>
              </ListItemIcon>
              <ListItemText primary={Translate.translate("LOG_OUT")} />
            </ListItemButton>
          </List>

          <center>
            <ButtonGroup variant="text">

            <Button onClick={toggleDrawer}>
              <img height="40" src={`${process.env.PUBLIC_URL}/lang.png`}  alt="" />
            </Button>
            <Button onClick={() => setLocale("en")}>
              <img height="20" src={`${process.env.PUBLIC_URL}/en.png`}   alt=""/>
            </Button>
            <Button onClick={() => setLocale("tr")}>
              <img height="20" src={`${process.env.PUBLIC_URL}/tr.png`}   alt=""/>
            </Button> 
            </ButtonGroup>
          </center>

          <Box sx={{ mt: 4, mb: 4, color:"#e1d9d9" }}>

          <center>
          {process.env.REACT_APP_VERSION}
          </center>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
