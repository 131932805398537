import { Grid, Typography, Breadcrumbs, Link, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Translate } from "../i18n/translate";
import NavBarItem from "./NavBarItem";

function NavBreadcrumbs(props) {
  const navigate = useNavigate();
  const handleclick = () => navigate(`/warehouses/${props.customer._id}`);

  function shortenText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
  
    const ellipsis = '...';
    const charactersToShow = maxLength - ellipsis.length;
    const firstHalfLength = Math.ceil(charactersToShow / 2);
    const secondHalfLength = Math.floor(charactersToShow / 2);
  
    const firstHalf = text.slice(0, firstHalfLength);
    const secondHalf = text.slice(-secondHalfLength);
  
    return `${firstHalf}${ellipsis}${secondHalf}`;
  }
  
  const section = (nav, index) => {
    if (nav.link) {
      return (
        <Link
          key={index}
          href="#"
          underline="hover"
          onClick={() => navigate(nav.link)}
        >
          {shortenText(`${Translate.translate(nav.name)}`, 60) }
        </Link>
      );
    } else {
      return (
        <Typography key={index} fontSize="inherit">
         {shortenText(`${Translate.translate(nav.name)}`, 60) }
        </Typography>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Typography variant="h4" gutterBottom>
            {Translate.translate(props.navs.title)}
          </Typography>

          <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
            <Link href="#" underline="hover" onClick={() => navigate("/")}>
              {Translate.translate("DASHBOARD")}
            </Link>
            {props.navs.items?.map((nav, index) => section(nav, index))}
          </Breadcrumbs>
        </Grid>
        <Grid item lg={6}>
          {props && props.navs && props.navs.navItems && (
            <Grid container sx={{display: "flex",
              justifyContent: "flex-end"}}>
              {props.navs.navItems.map((item, index) => (
                <NavBarItem
                  sx={{ mr: 1 }}
                  key={index}
                  id={item?.id}
                  title={item?.title}
                  image={item?.image}
                  callback={(e) => props.callback(e)}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 2 }} />
    </>
  );
}

export default NavBreadcrumbs;
