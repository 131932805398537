import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { WarehouseService } from "../../services/warehouse.services";
import { CSVLink } from "react-csv";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

const ViewCase = () => {
  const { warehouseId } = useParams();
  const [warehouse, setWarehouse] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const navigations = {
    title: "WAREHOUSES",
    items: [
      { name: "WAREHOUSES", link: "/warehouses" },
      { name: warehouse?.warehouseName, link: `/warehouses/${warehouse?._id}` },
      { name: "INVENTORY_LIST" },
    ],
  };

  useEffect(() => {
    fetchWarehouseData();
    fetchInventoryData();
  }, []);

  const fetchWarehouseData = async () => {
    try {
      const result = await WarehouseService.getOne(warehouseId);
      setWarehouse(result.data);
    } catch (error) {
      console.log("Error fetching warehouse data:", error);
    }
  };

  const fetchInventoryData = async () => {
    try {
      const result = await WarehouseService.getEnvanter(warehouseId, 0, 10);
      const csvData = result.data.reduce((csvArr, e) => {
        if (e.stock) {
          csvArr.push([e.stock.stockName, e.stock.barcode, e.sum]);
        }
        return csvArr;
      }, []);
      setCSVData(csvData);
      setTransactions(result.data);
    } catch (error) {
      console.log("Error fetching inventory data:", error);
    }
  };

  return (
    <Grid container spacing={0}>
      <NavBreadcrumbs title="WAREHOUSES" navs={navigations} />
      <Grid item xs={12}>
        <Divider
          sx={{
            mb: 2,
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <ButtonGroup variant="text" sx={{ float: "right" }}>
              <Button variant="outlined">
                {csvData && (
                  <CSVLink
                    className="MuiButtonBase-root:disabled"
                    filename={"envanter.csv"}
                    data={csvData}
                  >
                    {Translate.translate("DOWNLOAD")}
                  </CSVLink>
                )}
              </Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {warehouse?.warehouseName}{" "}
                {Translate.translate("INVENTORY_LIST")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                    <TableCell>{Translate.translate("BARCODE")}</TableCell>
                    <TableCell align="right">
                      {Translate.translate("QUANTITY")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((t, i) => (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{t.stock?.stockName}</TableCell>
                      <TableCell>{t.stock?.barcode}</TableCell>
                      <TableCell align="right">
                        <Chip
                          size="small"
                          label={t.sum}
                          color={t.sum < 0 ? "error" : "success"}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ViewCase;
