import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { Translate } from "../../i18n/translate";

export default function Warehouses() {
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={10}>
          <Typography variant="h4" gutterBottom>
            {Translate.translate("INTEGRATION")}
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link href="#" underline="hover" onClick={() => navigate("/")}>
          {Translate.translate("DASHBOARD")}
        </Link>
        <Typography fontSize="inherit">
          {" "}
          {Translate.translate("INTEGRATION")}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Paper sx={{ p: 2 }}>
            <Alert>{Translate.translate("INTEGRATION_INFO")}</Alert>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
