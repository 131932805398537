import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function StockCard(props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea
          onClick={() => navigate(`/warehouses/${props.warehouse._id}`)}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              {props.warehouse.warehouseName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
