import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  ButtonGroup,
  Grid,
} from "@mui/material";
import { AuthManager } from "../helpers/authManager";
import { useNavigate } from "react-router-dom";
import { LangContext } from "../i18n/LangContext";
import { Translate } from "../i18n/translate";
import AuthService from "../helpers/AuthService";
import { AlertDialog } from "../helpers/helper.alertDialog";
import { ToastContainer } from "react-toastify";

const theme = createTheme();
const authService = AuthService.getInstance();

export default function SignIn() {
  const navigate = useNavigate();
  const [action, setAction] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState();
  const [validationCode, setvalidationCode] = useState();
  const [first_name, setfirst_name] = useState();
  const [last_name, setlast_name] = useState();
  const { setLangContext } = useContext(LangContext);

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString();
    const lastRefreshDate = localStorage.getItem("lastRefreshDate");
    const locale = localStorage.getItem("locale");
    if (lastRefreshDate !== currentDate) {
      localStorage.setItem("lastRefreshDate", currentDate);
      window.location.reload();
    }
    if (locale !== "tr" || locale !== "en") {
      localStorage.setItem("locale", "tr");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    if (!email || !password) {
      const missingFieldsMsg = Translate.translate("MISSING_REQUIRED_FIELDS");
      AlertDialog.showAlert(missingFieldsMsg);
      return;
    }

    try {
      const user = await signIn(email, password);
      setLocalVariables(user);
      navigate("/");
    } catch (error) {
      const errorMsg = error.response?.data?.message || "UNKNOWN_ERROR";
      const translatedErrorMsg = Translate.translate(errorMsg);
      AlertDialog.showAlert(translatedErrorMsg);
      console.error(error);
    }
  };

  const setLocalVariables = (vars) => {
    const { token, first_name, last_name, isPremiumSubscriber, numofdevices } =
      vars;

    // Use destructuring assignment to simplify the code
    const fullName = `${first_name} ${last_name}`;
    const nameInitials = `${first_name[0]}${last_name[0]}`;

    // Use template literals for consistency and readability
    localStorage.setItem("token", token);
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("nameInitials", nameInitials);
    localStorage.setItem("isPremiumSubscriber", isPremiumSubscriber);
    localStorage.setItem("numofdevices", numofdevices);

    // Assume setLangContext is a function for setting the language context
    setLangContext("tr");
  };

  const signIn = async (email, password) => {
    try {
      const response = await authService.signIn(email, password);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const resetEmail = async () => {
    try {
      await AuthManager.resetEmail(email);
      setAction("newPassword");
    } catch (error) {
      const errorMsg = error.response?.data?.message || "An error occurred.";
      const translatedMsg = Translate.translate(errorMsg);
      AlertDialog.showAlert(translatedMsg);
    }
  };

  const setPassword = async () => {
    const payload = {
      email,
      activationCode: validationCode,
      password,
    };

    try {
      await AuthManager.setPassword(payload);
      setAction("login");
    } catch (error) {
      const errorMsg = error.response?.data?.message || "An error occurred.";
      const translatedMsg = Translate.translate(errorMsg);
      AlertDialog.showAlert(translatedMsg);
    }
  };

  const register = async () => {
    if (!first_name || !last_name || !email || !password) {
      AlertDialog.showAlert(Translate.translate("MISSING_REQUIRED_FIELDS"));
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      AlertDialog.showAlert(Translate.translate("NOT_A_VALID_EMAIL"));
      return;
    }

    const payload = {
      first_name,
      last_name,
      email,
      password,
    };

    try {
      const user = await AuthManager.register(payload);

      if (user) {
        AlertDialog.showSuccess(Translate.translate("OPERATION_IS_SUCCESSFUL"));
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        AlertDialog.showAlert(Translate.translate("REGISTRATION_FAILED"));
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || Translate.translate("UNKNOWN_ERROR");
      const translatedMsg = Translate.translate(errorMsg);
      AlertDialog.showAlert(translatedMsg);
    }
  };

  function setLocale(locale) {
    console.log("event", locale);
    localStorage.setItem("locale", locale);
    setLangContext(locale);
  }

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ borderRadius: 40 }}
            src={`${process.env.PUBLIC_URL}/Icon-App-20x20@3x.png`}
            alt=""
          />

          <Typography component="h1" variant="h3">
            eltrm
          </Typography>
          {action === "login" && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={Translate.translate("EMAIL")}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={Translate.translate("PASSWORD")}
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {Translate.translate("LOGIN")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setAction("reset");
                    }}
                  >
                    {Translate.translate("PASSWORD_RESET")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setAction("register");
                    }}
                  >
                    {Translate.translate("REGISTER")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {action === "reset" && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography variant="h6" gutterBottom>
                {Translate.translate("PASSWORD_RESET")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {Translate.translate("PASSWORD_RESET_TEXT")}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={Translate.translate("EMAIL")}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <Button
                onClick={() => {
                  resetEmail();
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {Translate.translate("SUBMIT")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setAction("login");
                    }}
                  >
                    {Translate.translate("BACK")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {action === "register" && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography variant="h6" gutterBottom>
                {Translate.translate("REGISTER")}
              </Typography>

              <TextField
                margin="normal"
                required
                fullWidth
                id="first_name"
                label={Translate.translate("FIRST_NAME")}
                name="first_name"
                autoComplete="first_name"
                autoFocus
                onChange={(e) => setfirst_name(e.target.value)}
                value={first_name}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="last_name"
                label={Translate.translate("LAST_NAME")}
                name="last_name"
                autoComplete="last_name"
                autoFocus
                onChange={(e) => setlast_name(e.target.value)}
                value={last_name}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={Translate.translate("EMAIL")}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <Typography variant="body1" gutterBottom>
                🚨 {Translate.translate("REGISTER_PAGE_INFO")}
              </Typography>

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={Translate.translate("PASSWORD")}
                type="text"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
              <Button
                onClick={() => {
                  register();
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {Translate.translate("SUBMIT")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setAction("login");
                    }}
                  >
                    {Translate.translate("BACK")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {action === "newPassword" && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography variant="h6" gutterBottom>
                {Translate.translate("RESET_PASSWORD")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {Translate.translate("SET_NEW_PASSWORD_TITLE")}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="validationCode"
                label="Doğrulama Kodu"
                name="validationCode"
                autoComplete="validationCode"
                autoFocus
                onChange={(e) => setvalidationCode(e.target.value)}
                value={validationCode}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Yeni Şifre"
                type="text"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
              <Button
                onClick={() => {
                  setPassword();
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {Translate.translate("REFRESH")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setAction("login");
                    }}
                  >
                    GERİ DÖN
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Box>
          <center>
            <br />
            <a
              target="_blank"
              href="https://apps.apple.com/tr/app/el-terminali/id1116086125?l=tr"
              className="m-r-10"
              rel="noreferrer"
            >
              <img
                height="60"
                src={`${process.env.PUBLIC_URL}/apple.png`}
                alt=""
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.makdeniz.elterminali"
            >
              <img
                height="60"
                src={`${process.env.PUBLIC_URL}/android.png`}
                alt=""
              />
            </a>
          </center>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center">
          Copyright © eltrm 2023.
        </Typography>
        <center>
          <ButtonGroup variant="text">
            <Button onClick={() => setLocale("en")}>
              <img
                height="20"
                src={`${process.env.PUBLIC_URL}/en.png`}
                alt=""
              />
            </Button>
            <Button onClick={() => setLocale("tr")}>
              <img
                height="20"
                src={`${process.env.PUBLIC_URL}/tr.png`}
                alt=""
              />
            </Button>
          </ButtonGroup>
        </center>
      </Container>
    </ThemeProvider>
  );
}
