import {
  Box,
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  HourglassBottom,
  NotificationImportant,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

export default function StockCard({ post, index }) {
  const navigate = useNavigate();

  const color =
    (post.totalQuantity < post.notificationLimit) | 0 ? "red" : "inherit";

  const totalQuantity = post.totalQuantity ?? 0;
  const shouldHideQuantity = totalQuantity === 0;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={() => navigate(`/stocks/${post._id}`)}>
          <CardMedia
            component="img"
            height="140"
            image={
              post.images?.length > 0
                ? `${process.env.REACT_APP_API_URL}/images/${post.images[0].image}`
                : `${process.env.PUBLIC_URL}/noimg.jpg`
            }
            alt="green iguana"
          />
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {post.barcode}
            </Typography>
            <Typography variant="h5" component="div">
              {post.stockName}
            </Typography>
            <Typography variant="body2">{post.stockCode}</Typography>
          </CardContent>
          <CardActions style={{ backgroundColor: "#f1f1f1" }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              <NotificationImportant sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant="caption">
                {post.notificationLimit ?? 0}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              <ArrowDownward sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant="caption">
                <NumericFormat
                  decimalScale={3}
                  value={post.purchasePrice ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"₺"}
                />
              </Typography>
            </Box>
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", ml: 1 }}
            >
              <ArrowUpward sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant="caption">
                <NumericFormat
                  decimalScale={3}
                  value={post.salePrice ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"₺"}
                />
              </Typography>
            </Box>
            {!shouldHideQuantity && (
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <HourglassBottom
                  sx={{ width: 16, height: 16, mr: 0.5, color: color }}
                />
                <Typography variant="caption" sx={{ color: color }}>
                  <NumericFormat
                    decimalScale={3}
                    value={post.totalQuantity ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            )}
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
