import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Typography,
} from "@mui/material"; 
import { ReportService, TransactionService } from "../services";
import { NumericFormat } from "react-number-format";
import { Translate } from "../i18n/translate"; 
import TransactionTable from "../sections/TransactionTable";

function HomePage() {
  const [transactions, settransactions] = useState([]);
  const [page, setpage] = useState(0);
  const [dashboardValues, setdashboardValues] = useState();
 
  useEffect(() => {
    getTransactions();
    getDashboardReports();
  }, []);

  

  function loadmore() {
    setpage(page + 1);
    getTransactions(page);
    // console.log("LOADMORE", this.state.page);
  }

  function getDashboardReports() {
    ReportService.dashboard()
      .then((result) => {
        // console.error(result.data);
        setdashboardValues(result.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function getTransactions(page = 0) {
    if (localStorage.getItem("isPremiumSubscriber") !== "true") {
      return;
    }
    TransactionService.getAll(page, 10)
      .then((result) => {
        // console.log(result.data);
        settransactions(transactions.concat(result.data));
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <Breadcrumbs separator="›"> 
          <Typography fontSize="inherit">
            {Translate.translate("DASHBOARD")}
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {Translate.translate("NUMBER_OF_SAVED_STOCKS")}
          </Typography>
          <Typography component="p" variant="h4">
            <NumericFormat
              displayType={"text"}
              thousandSeparator={true}
              value={dashboardValues?.stockCount}
            />
          </Typography>
          <Typography color="text.secondary" variant="caption" sx={{ flex: 1 }}>
            {Translate.translate("NUMBER_OF_SAVED_STOCKS")}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {Translate.translate("NUMBER_OF_SAVED_WAREHOUSES")}
          </Typography>
          <Typography component="p" variant="h4">
            <NumericFormat
              displayType={"text"}
              thousandSeparator={true}
              value={dashboardValues?.warehouseCount}
            />
          </Typography>
          <Typography color="text.secondary" variant="caption" sx={{ flex: 1 }}>
            {Translate.translate("NUMBER_OF_SAVED_WAREHOUSES")}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {Translate.translate("NUMBER_OF_STOCKS_IN_INVENTORY")}
          </Typography>
          <Typography component="p" variant="h4">
            <NumericFormat
              displayType={"text"}
              thousandSeparator={true}
              value={dashboardValues?.totalStockQuantity ?? 0}
              decimalScale={3}
            />
          </Typography>
          <Typography color="text.secondary" variant="caption" sx={{ flex: 1 }}>
            {Translate.translate("NUMBER_OF_STOCKS_IN_INVENTORY")}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {Translate.translate("TOTAL_VALUE")}
          </Typography>
          <Typography component="p" variant="h4">
            <NumericFormat
              displayType={"text"}
              thousandSeparator={true}
              value={dashboardValues?.totalValue}
              decimalScale={3}
            />
          </Typography>
          <Typography color="text.secondary" variant="caption" sx={{ flex: 1 }}>
            {Translate.translate(
              "TOTAL_FINANCIAL_VALUE_OF_STOCKS_IN_INVENTORY"
            )}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
          }}
        >
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {Translate.translate("RECENT_TRANSACTIONS")}
          </Typography>

          <TransactionTable transactions={transactions} />

          {localStorage.getItem("isPremiumSubscriber") === "true" && (
            <Button size="small" onClick={() => loadmore()} sx={{ mt: 2 }}>
              {Translate.translate("MORE")}
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default HomePage;
