import React, { useEffect, useState } from "react";
import { Grid, TextField, Box, Button } from "@mui/material";
import { Translate } from "../../i18n/translate";

export default function GeneralSettings(props) {
  const [accountName, setAccountName] = useState(props.account.accountName || '');  
  const [lang, setLang] = useState(props.account.lang || '');  

  useEffect(() => {
    setAccountName(props.account.accountName || '');  
    setLang(props.account.lang || '');  
  }, [props.account]);
 
  const handleUpdate = () => { 
    props.callback({lang,accountName});
  };

  return (
    <Grid>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "37ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          sx={{ mb: 3 }}
          label={Translate.translate("ACCOUNT_NAME")}
          fullWidth
          value={accountName}
          onChange={(e)=>setAccountName(e.target.value)}
        />

        <TextField
          select
          fullWidth
          label={Translate.translate("NOTIFICATIONS_LANGUAGE")}
          value={lang} 
          onChange={(e)=>setLang(e.target.value)}
          sx={{ mb: 3 }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="tr">Türkçe</option>
          <option value="en">English</option>
        </TextField>
      </Box>

      <Button onClick={handleUpdate} sx={{ mt: 3 }}>
        {Translate.translate("UPDATE")}
      </Button>
    </Grid>
  );
}
