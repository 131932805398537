import { ServiceManager } from "./serviceManager";

export const UserService = {
  getAll,
  activateToggle,
  getTransaction,
  getQuantity,
  save,
  search,
  update,
  deleteUser,
  multisave,
  renewQRCode,
};

function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`admin/users?limit=${limit}&page=${page}`);
}
function save(payload) {
  return ServiceManager.Post(`admin/users`, payload);
}

function activateToggle(id) {
  return ServiceManager.Get(`admin/users/${id}/activate`);
}

function deleteUser(id) {
  return ServiceManager.Delete(`admin/users/${id}`);
}

function renewQRCode(id) {
  return ServiceManager.Get(`admin/users/${id}/renewQRCode`);
}
// *******************************

function getTransaction(id, page = 0, limit = 10) {
  return ServiceManager.Get(
    `stocks/${id}/transactions?limit=${limit}&page=${page}`
  );
}
function getQuantity(id) {
  return ServiceManager.Get(`stocks/${id}/quantity`);
}

function search(payload) {
  return ServiceManager.Post(`stocks/search`, payload);
}

function update(id, payload) {
  return ServiceManager.Put(`stocks/${id}`, payload);
}

function multisave(payload) {
  return ServiceManager.Post(`stocks/multi`, payload);
}
