import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import { Translate } from "../../i18n/translate";
import { CustomerService, OrderService } from "../../services";
import CustomerCard from "../../sections/CustomerCard";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import OrderCard from "../../sections/OrderCard";
import OrderItemsTable from "./OrderItemsTable";
import SelectView from "../../sections/SelectView";

function ViewOrder() {
  const { orderId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState();
  const [page, setPage] = useState(0);
  const [navItems, setNavItems] = useState(0);
  const [selectViewItems, setSelectViewItems] = useState();

  const navigations = {
    title: "ORDER",
    items: [{ name: "ORDERS", link: "/orders" }, { name: `${order?.orderNo}` }],
    navItems: navItems,
  };

  const navBarItemsConfig2 = {
    save: { id: "save", title: "SAVE", image: "save.png" },
    previewDeliveryNote: {
      id: "previewDeliveryNote",
      title: "PREVIEW_DELIVERY_NOTE",
      image: "save.png",
    },
    updateOrder: {
      id: "updateOrder",
      title: "UPDATE_ORDER_STATUS",
      image: "update.png",
    },
    scan: { id: "scan", title: "ADD_ITEMS", image: "barcode.png" },
    submitOrder: {
      id: "submitOrder",
      title: "SUBMIT_ORDER",
      image: "save.png",
    },
    selectCustomer: {
      id: "selectCustomer",
      title: "SELECT_CUSTOMER",
      image: "customers_selected.png",
    },
    selectOrderDate: {
      id: "selectOrderDate",
      title: "SELECT_ORDER_DATE",
      image: "calendar.png",
    },
    deleteOrder: {
      id: "deleteOrder",
      title: "DELETE_ORDER",
      image: "trash.png",
    },
    selectWarehouse: {
      id: "selectWarehouse",
      title: "SELECT_WAREHOUSE",
      image: "warehouses_selected.png",
    },
  };

  useEffect(() => {
    fecthRecords();
  }, [page]);

  const fecthRecords = async () => {
    try {
      const result = await OrderService.get(orderId);
      let order = result.data;
      setOrder(order);

      let arr = [];
      order?.currentAction.actionBarItems.map((action, index) =>
        arr.push(navBarItemsConfig2[action])
      );
      setNavItems(arr);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const actions = {
    updateOrder: updateOrder,
    scan: scan,
  };

  // Main function to take action based on input
  function takeAction(action) {
    console.log("action:", action);
    if (action === "updateOrder") {
      let status = order.possibleActions.map((item) => {
        return {
          id: item.code,
          title: Translate.translate(item.name),
          color: item.color,
        };
      });
      console.log("....", status);
      setSelectViewItems({ id: "orderStatus", items: status });
      setShowModal(true);
      console.log("Invalid action:", action);
    }
    if (action === "selectCustomer") {
      selectCustomer();
    }
  }

  async function selectCustomer() {
    let customers = await CustomerService.getAll();

    console.log(customers.data);
    let status = customers.data.map((item) => {
      return {
        id: item._id,
        title: `${item.firstName} ${item.lastName}`,
      };
    });
    console.log("....", status);
    setSelectViewItems({ id: "selectCustomer", items: status });
    setShowModal(true);
  }

  function updateOrder() {
    console.log("updateOrder ACTION");
  }
  function scan() {
    console.log("scan ACTION");
  }

  const setStatus = async (code) => {
    let update = await OrderService.update(order._id, {
      orderStatus: code,
      isOrderUpdate: true,
    });
    fecthRecords();
  };

  const setCustomer = async (id) => {
    let update = await OrderService.update(order._id, {
      customer: id,
    });
    fecthRecords();
  };

  function selectViewCallback(e) {
    setShowModal(false);
    if (e.id === "orderStatus") {
      setStatus(e.callbackId);
      console.log("Set orderStatus", e);
    }
    if (e.id === "selectCustomer") {
      setCustomer(e.callbackId);
      console.log("Set orderStatus", e);
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={takeAction} />

      <Grid sx={{ mt: 2 }} container spacing={2}>
        {order && <OrderCard order={order} />}
        {order && <CustomerCard customer={order?.customer[0]} />}
      </Grid>
      <SelectView
        selectViewItems={selectViewItems}
        open={showModal}
        callback={selectViewCallback}
      />
      <Divider sx={{ mb: 2, mt: 2 }} />

      {order && <OrderItemsTable orderItems={order.orderItems} />}
    </>
  );
}

export default ViewOrder;
