import { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { WarehouseService } from "../../services/warehouse.services";
import WarehouseCard from "../../sections/WarehouseCard";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import { useNavigate } from "react-router-dom";

export default function Warehouses() {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState([]);
  const navigations = {
    title: "WAREHOUSES",
    items: [{ name: "WAREHOUSES" }],
    navItems: [
      { id: "new", title: "NEW", image: "plus.png" }
    ],
  };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async (page) => {
    let result = await WarehouseService.getAll(100, 1);
    setStocks(result.data);
  };

  function navBarAction(id){
  if(id === "new"){
    navigate("/createWarehouse");
  }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations}  callback={navBarAction}/>
   
      <Grid container spacing={3} sx={{ mt: 0 }}>
        {stocks.map((warehouse, index) => (
          <WarehouseCard key={index} warehouse={warehouse} />
        ))}
      </Grid>
    </>
  );
}
