const OrdersLang = {
  ORDERS_ITEMS: {
    en: "Order Items",
    tr: "Sipariş Kalemleri",
  },
  ORDERS: {
    en: "Orders",
    tr: "Siparişler",
  },
  ORDER: {
    en: "Order",
    tr: "Sipariş",
  },
};

const OrdersActions = {
  ORDERS_ITEMS: {
    en: "Order Items",
    tr: "Sipariş Kalemleri",
  },
  UPDATE_ORDER_STATUS: {
    en: "Update Order Status",
    tr: "Sipariş Statü Güncelle",
  },
  ADD_ITEMS: {
    en: "Add Items",
    tr: "Stok Ekle",
  },
  SELECT_CUSTOMER: {
    en: "Select Customer",
    tr: "Müşteri Seç",
  },
  SELECT_ORDER_DATE: {
    en: "Select Order Date",
    tr: "Tarih Seç",
  },
  SELECT_WAREHOUSE: {
    en: "Select Warehouse",
    tr: "Depo Seç",
  },
  DELETE_ORDER: {
    en: "Delete Order",
    tr: "Siparişi Sil",
  },
};
const TranslationOrderStatus = {
  orderInPreparation: {
    en: "Order In Preparation",
    tr: "Hazırlık Aşamasında",
  },
  orderPlaced: {
    en: "Order Placed",
    tr: "Sipariş Alındı",
  },
  orderProcessing: {
    en: "Order Processing",
    tr: "Sipariş İşleniyor",
  },
  orderConfirmed: {
    en: "Order Confirmed",
    tr: "Sipariş Onaylandı",
  },
  paymentProcessing: {
    en: "Payment Processing",
    tr: "Ödeme İşleniyor",
  },
  orderItemsExportedFromWarehouse: {
    en: "Stocks are exported from Warehouse",
    tr: "Stoklar Depodan Sevk Edildi",
  },
  orderFulfillment: {
    en: "Order Fulfillment",
    tr: "Sipariş Hazırlanıyor",
  },
  orderShipped: {
    en: "Order Shipped",
    tr: "Sipariş Gönderildi",
  },
  inTransit: {
    en: "In Transit",
    tr: "Yolda",
  },
  outForDelivery: {
    en: "Out For Delivery",
    tr: "Teslimat İçin Yolda",
  },
  orderDelivered: {
    en: "Order Delivered",
    tr: "Sipariş Teslim Edildi",
  },
  orderCompleted: {
    en: "Order Completed",
    tr: "Sipariş Tamamlandı",
  },
  orderCanceled: {
    en: "Order Canceled",
    tr: "Sipariş İptal Edildi",
  },
  orderReturned: {
    en: "Order Returned",
    tr: "Sipariş İade Edildi",
  },
  orderRefunded: {
    en: "Order Refunded",
    tr: "Sipariş İade Edildi",
  },
  orderArchiving: {
    en: "Order Archiving",
    tr: "Sipariş Arşivleniyor",
  },
};
const CombinedOrders = {
  ...OrdersLang,
  ...OrdersActions,
  ...TranslationOrderStatus,
};

export default CombinedOrders;
