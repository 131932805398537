import axios from "axios";
import { useNavigate } from "react-router";
import { ServiceManager } from "../helpers/serviceManager";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
const headers = {
  headers: { Authorization: `Bearer ${token}` },
};

const isLogin = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};

const login = async (email, password) => {
   await ServiceManager.Post("auth/signin", {
    email: email,
    password: password,
    source: "WEB",
  })
    .then((login) => {
      setLocalVariables(login.data);
      return Promise.resolve(login.data);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const setLocalVariables = (vars) => {
  localStorage.setItem("token", vars.token);
  localStorage.setItem("fullName", `${vars.first_name} ${vars.last_name}`);
  localStorage.setItem(
    "nameInitials",
    `${vars.first_name.toUpperCase().substring(0, 1)}${vars.last_name
      .toUpperCase()
      .substring(0, 1)}`
  );
  localStorage.setItem("isPremiumSubscriber", vars.isPremiumSubscriber);
  localStorage.setItem("numofdevices", vars.numofdevices); 
};

const registerDevice = async (deviceCode) => {
  if (isLogin() === true) {
    console.log("Device Registered");
    return;
  }

  console.log("Device is being registered");

  let paylod = {};

  let register = await ServiceManager.Post(`auth/registerDevice`, paylod);
  console.log("token>>", register.data.token);
  localStorage.setItem("token", register.data.token);

  // window.location.reload(false);
  return register.data;
};

const resetEmail = async (email) => {
  let paylod = {
    email: email,
  };
  console.log(paylod);
  return ServiceManager.Post(`auth/reset`, paylod);
};

const setPassword = async (paylod) => {
  console.log(paylod);
  return ServiceManager.Post(`auth/setnewpassword`, paylod);
};
const register = async (paylod) => {
  console.log(paylod);

  let register = await ServiceManager.Post(`auth/register`, paylod);
  if (register) {
    console.log(register)
    setLocalVariables(register.data);
    return register.data;
  } else {
    return false;
  }
};

export const AuthManager = {
  register,
  registerDevice,
  isLogin,
  login,
  resetEmail,
  setPassword,
};
